// @utility collapsible block
// @param {NodeList|Array} `triggerNodes` Nodes whom triggers the collapsible targets
// @param {NodeList|Array} `targetNodes` Nodes that are collapsible
// @param {Boolean} `closable` If true it closes when clicking outside
// @param {Boolean} `visuel` Si la cible doit être affichée visuellement
// @return {Nodes} Modified nodes
// @usage Collapsible(document.querySelectorAll(myTriggerSelector), document.querySelectorAll(myTargetSelector));
module.exports = (triggerNodes, targetNodes, closable, visuel=true) => {
  const getClosest = require('./get-closest.js');
  const triggerCustomEvent = require('./trigger-custom-event.js');
  const triggers = [].slice.call(
    (triggerNodes || document.querySelectorAll('[data-js-collapsible="trigger"]'))
  );
  const targets = [].slice.call(
    (targetNodes || document.querySelectorAll('[data-js-collapsible="target"]'))
  );

  const setTriggerExpandedClass = triggerNode => {
    const expandedClass = 'is--expanded';

    if (triggerNode.querySelector('button[aria-expanded]').getAttribute('aria-expanded') === 'true') {
      return triggerNode.classList.add(expandedClass);
    } else {
      return triggerNode.classList.contains(expandedClass) &&
        triggerNode.classList.remove(expandedClass);
    }
  };

  const setClosableClasses = (triggerButton, target) => {
    triggerButton.dataset.jsClosable = 'trigger';
    target.dataset.jsClosable = 'target';

    return;
  };

  const listenCloseButton = (triggerButton, target) => {
    if (target.querySelector('[data-js-collapsible="close"]')) {

      target.querySelector('[data-js-collapsible="close"]').addEventListener('click', () => {
        triggerButton.click();
        triggerButton.focus();
      });
    }

    return;
  };

  const closeExpandedClosable = () => {
    const expandedClosableTrigger = '[data-js-closable="trigger"][aria-expanded="true"]';
    const expandedClosableTarget = '[data-js-closable="target"]:not([hidden])';

    // When click outside
    document.body.addEventListener('click', evt => {
      let expandedTriggers = [].slice.call(document.querySelectorAll(expandedClosableTrigger));

      if (expandedTriggers.length > 0
          && !getClosest(evt.target, expandedClosableTrigger)
          && !getClosest(evt.target, expandedClosableTarget)) {
        evt.preventDefault();

        expandedTriggers.forEach(expanded => {
          expanded.click();
        });
      }
    });

    // When escape
    document.body.addEventListener('keydown', evt => {
      let expandedTriggers = [].slice.call(document.querySelectorAll(expandedClosableTrigger));

      if (expandedTriggers.length > 0
          && (evt.keyCode || evt.which) === 27) {
        evt.preventDefault();

        expandedTriggers.forEach(expanded => {
          expanded.click();
        });
      }

      return;
    });
  };

  // The number of triggers & targets must be equivalent
  if (triggers.length === targets.length
      && triggers.length > 0) {
    triggers.forEach((trigger, index) => {
      let target = targets[index];

      // Set a button into the declared `trigger`
      trigger.innerHTML = `<button aria-expanded="${!target.hasAttribute('hidden')}" data-js-collapsible="button" type="button">${trigger.innerHTML}</button>`;

      // Set a class if the aria-expanded is at true
      setTriggerExpandedClass(trigger);

      // Listen the click to expand/collapse the `target`
      if ( visuel ) {
        trigger.querySelector('button').addEventListener('click', evt => {
            let expanded = evt.currentTarget.getAttribute('aria-expanded') === 'true';

            evt.preventDefault();
            evt.currentTarget.setAttribute('aria-expanded', !expanded);
            target.hidden = expanded;

            // Set a class if the aria-expanded is at true
            setTriggerExpandedClass(evt.currentTarget.parentNode);

            triggerCustomEvent(target, 'collapse', {isExpanded: !expanded, triggerNode: evt.currentTarget, targetNode: target});
        });
      }

      // Only if closable is set to `true`
      if (closable) {
        setClosableClasses(trigger.querySelector('button'), target);
        listenCloseButton(trigger.querySelector('button'), target);
      }
    });

    // Only if closable is set to `true`
    if (closable) {
      closeExpandedClosable();
    }
  }

  return;
};
