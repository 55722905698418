/**
 * Get the closest matching element up the DOM tree.
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 * @source <https://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/>
 */
module.exports = (elem, selector) => {
    const matches = require('./matches.js');

	// Get closest match
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if ( matches( elem, selector ) ) return elem;
	}

	return null;
};
