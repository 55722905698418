// @module menuBdl
const menuBdl = (function() {
  const itemsBdl = [].slice.call(document.querySelectorAll('[data-js-menubdl="menubdl"]'));

  const init = () => {
    return itemsBdl.forEach(item => {
        const articles = [].slice.call(item.querySelectorAll('[data-js-menubdl="article"]'));
        const themes = [].slice.call(item.querySelectorAll('[data-js-menubdl="theme"]'));
        
        var i = 0;
        var label = 'article';
        
        articles.forEach(article => {
            article.style.display = "none";
            i++;
        });
        themes.forEach(theme => {
            theme.style.display = "none";
        });
        
        if( articles.length > 1 ){
            label = 'articles';
        }
        item.querySelectorAll('a')[0].innerHTML += ' ['+articles.length+' '+label+']';
        
    });
  };

  return {
    init : init
  };
})();


module.exports = menuBdl;