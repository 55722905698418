// @module rel external
const RelExternal = (function() {
  const hyperlinks = [].slice.call(document.querySelectorAll('a[href]'));
  const matches = require('../utils/matches.js');

  const setExternalLinkIcon = externalLink => {
    if (!externalLink.classList.contains('no-external')
        && !externalLink.classList.contains('button')
        && !externalLink.querySelector('img')
        && externalLink.href.indexOf('vitrinelinguistique.oqlf.gouv.qc.ca') == -1
        && !matches(externalLink.parentNode, 'figure.figure')) {
      externalLink.insertAdjacentHTML(
        'afterend',
        '<svg aria-hidden="true" class="ico-relexternal" height="11px" role="img" width="11px"><use xlink:href="#ico_lienexterne"></use></svg>'
      );
      externalLink.insertAdjacentHTML('beforeend', '<span class="visuallyhidden">(Cet hyperlien externe s\'ouvrira dans une nouvelle fenêtre.)</span>');
    }

    // When external links looks like button OR contains image
    if (!externalLink.classList.contains('no-external')
        && externalLink.classList.contains('button')
        || externalLink.querySelector('img')) {
      externalLink.setAttribute('title', 'Cet hyperlien externe s\'ouvrira dans une nouvelle fenêtre.');
    }

    // Si le lien n'a pas déjà une target de spécifié
    if ( !externalLink.hasAttribute('target')) {
      externalLink.setAttribute('target', '_blank');
    }
    
    externalLink.setAttribute('rel', 'external');

    return;
  };

  const init = () => {
    hyperlinks.forEach( hyperlink => {
      if (hyperlink.hostname && location.hostname !== hyperlink.hostname) {
        setExternalLinkIcon(hyperlink);
      }
    });

    return;
  };

  return {
    init : init
  };
})();

module.exports = RelExternal;
