// @module faq
const FAQ = (function() {
  const faqs = [].slice.call(document.querySelectorAll('[data-js-faq="faq"]'));

  const init = () => {
    return faqs.forEach(faq => {
      const questions = [].slice.call(faq.querySelectorAll('[data-js-faq="faqquestion"]'));
      const toggleSwitch = faq.querySelector('[data-js-switch]');

      if (questions.length > 1) {
        toggleSwitch.querySelector('[role="switch"]').addEventListener('onswitch', evt => {
          let checked = evt.detail.isChecked === true;

          questions.forEach(question => {
            question.open = checked;
          });
        });
      } else {
        toggleSwitch.parentNode.removeChild(toggleSwitch);
      }
    });
  };

  return {
    init : init
  };
})();

module.exports = FAQ;
