/**
 * Matches Selector
 * @private
 * @param  {Element} elem Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean} true if the element would be selected by the specified selector string; otherwise, returns false
 * @usage matches(elem, '.my-class');
 * @source <http://youmightnotneedjquery.com/>
 */

module.exports = (elem, selector) => {
    return (elem.matches
            || elem.matchesSelector
            || elem.msMatchesSelector
            || elem.mozMatchesSelector
            || elem.webkitMatchesSelector
            || elem.oMatchesSelector).call(elem, selector);
};
