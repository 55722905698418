// TOC navigation module
const NavTOC = (function() {
    const navToc = document.querySelector('[data-js-toc=""]');
    const throttle = require('../utils/throttle.js');
    let hasExecuted = false;

    // Close the TOC if it's at the edge of the window
    const collapseAtTheEdge = () => {
        const trigger = navToc.querySelector('[data-js-accordion="trigger"]');
        const isExpanded = (trigger.getAttribute('aria-expanded') === "true");
        const isAtTop = (navToc.getBoundingClientRect().top === 0);

        if (isExpanded && isAtTop && !hasExecuted) {
            hasExecuted = true;

            return setTimeout(() => {
                trigger.click();
            }, 500);
        }

        return null;
    };

    // Initialize method
    const init = () => {
        if (navToc) {
            return window.addEventListener('scroll', evt => {
                requestAnimationFrame(collapseAtTheEdge);
            });
        }

        return null;
    };

    return {
        init : init
    };
})();

module.exports = NavTOC;
