// @module form
const Form = (function() {
  const forms = [].slice.call(document.querySelectorAll('[data-js-form="form"]'));
  const isElement = require('../utils/is-element.js');
  const matches = require('../utils/matches.js');

  const getRequiredFields = form => {
    return [].slice.call(form.querySelectorAll(':required'));
  };

  const getFormControls = form => {
    return [].slice.call(form.querySelectorAll('input:not([type="hidden"]), select, textarea'));
  };

  // Set a required class to the labels of required fields
  const setRequiredLabels = required => {
    const label = document.querySelector(`[for="${required.id}"]`);

    if (label
        && !matches(required, '[type="radio"], [type="checkbox"]')) {
      label.insertAdjacentHTML('beforeend', '<b aria-hidden="true" class="asterisk">*</b>');

      return label.classList.add('required');
    }

    return null;
  };

  // Set a required class to the legend of fieldsets within there is required fields
  const setRequiredLegends = form => {
    [].slice.call(form.querySelectorAll('fieldset')).forEach(fieldset => {
      const legend = fieldset.querySelector('legend');

      if (legend
          && fieldset.contains(fieldset.querySelector(':required'))) {
        return legend.classList.add('required');
      }

      return null;
    });
  };
  
  // Set a required class to the legend of fieldsets within there is required fields
  const setRequiredInputs = form => {
    [].slice.call(form.querySelectorAll('[data-parsley-required-message]')).forEach(field => {
      
      field.setAttribute('title', field.getAttribute('data-parsley-required-message'));

      return null;
    });
  };

  const toggleHelpText = field => {
    let help;

    if (field.hasAttribute('aria-describedby')) {
      help = document.getElementById(field.getAttribute('aria-describedby'));

      if (field.checkValidity()) {
        help.hidden = true;
        field.classList.contains('is-invalid') && field.classList.remove('is-invalid');
      } else {
        help.hidden = false;
        !field.classList.contains('is-invalid') && field.classList.add('is-invalid');
      }

      if (help.getAttribute('data-js-form-invalid') !== '') {
        help.innerHTML = help.getAttribute('data-js-form-invalid');
      }
    }

    return;
  };

  const setContactUs = () => {
    const contactUs = document.querySelector('#form_3') || document.querySelector('[action^="/nous-joindre?"]');

    if (contactUs) {
      const countries = contactUs.querySelector('[name="tx_powermail_pi1[field][pays]"]');
      const provinces = contactUs.querySelector('[name="tx_powermail_pi1[field][province]"]');

      if (countries && provinces) {
        // On load enable / disable provinces select element
        provinces.disabled = !(countries.value === 'CAN');
        provinces.required = (countries.value === 'CAN');

        // On change [idem]
        countries.addEventListener('change', evt => {
          provinces.disabled = !(evt.target.value === 'CAN');
          provinces.required = (countries.value === 'CAN');
        });
      }
    }

    return;
  };

  const sendCaptchaForm = (token, submitButton) => {
    console.log(token);
    return;
  };

  // Initialize method
  const init = () => {
    forms.forEach(form => {
      getRequiredFields(form).forEach(field => {
        setRequiredLabels(field);
      });

      setRequiredLegends(form);
      
      setRequiredInputs(form);

      // JIT validation
      getRequiredFields(form).forEach(field => {
          field.addEventListener('blur', evt => {
              toggleHelpText(evt.target);
          });

          field.addEventListener('input', evt => {
              toggleHelpText(evt.target);
          });
      });

      // Submit validation
      if (form.querySelector('[type="submit"]')) {
        form.querySelector('[type="submit"]')
          .addEventListener('click', evt => {
            let invalidList = evt.target.form.querySelectorAll(':invalid');

            getRequiredFields(form).forEach(field => {
              toggleHelpText(field);
            });

            if (invalidList.length > 0) {
              location.href = '#'+invalidList[0].getAttribute('aria-describedby');
            }
            else{
              evt.preventDefault();
              var targetElement = event.target || event.srcElement;
              //console.log(targetElement.form.elements["g-recaptcha-response"].dataset.functionName);
              if(typeof targetElement.form.elements["g-recaptcha-response"] !== 'undefined'){
                window[targetElement.form.elements["g-recaptcha-response"].dataset.functionName]();
              }
             // targetElement.form.submit();
            }
          });
      }
    });

    // Do specific tasks
    //setContactUs();

    return;
  };

  return {
    init : init
  };
})();

module.exports = Form;
