// Format date Module
const FormatHumanDate = (function () {

    const newsdate = [].slice.call(document.querySelectorAll(".date-news"));

    //Time units must be be ordered largest -> smallest
    const time_units = time_units || [
        [29030400, 'years'],
        [2419200, 'months'],
        [604800, 'weeks'],
        [86400, 'days'],
        [3600, 'hours'],
        [60, 'minutes'],
        [1, 'seconds']
    ];

    const tense = 'past';

    const humanized_time_span = (date, ref_date, date_formats, time_units) => {
        //Date Formats must be be ordered smallest -> largest and must end in a format with ceiling of null
        date = new Date(date);
        // date à l'instant T
        ref_date = ref_date ? new Date(ref_date) : new Date();

        let seconds_difference = (ref_date - date) / 1000;

        return render_date(get_format(seconds_difference), seconds_difference);
    };

    const get_format = (seconds_difference) => {
        for (var i = 0; i < date_formats[tense].length; i++) {
            if (date_formats[tense][i].ceiling == null || seconds_difference <= date_formats[tense][i].ceiling) {
                return date_formats[tense][i];
            }
        }
        return null;
    };

    const get_time_breakdown = (seconds_difference) => {
        var seconds = seconds_difference;
        var breakdown = {};
        for (var i = 0; i < time_units.length; i++) {
            var occurences_of_unit = Math.floor(seconds / time_units[i][0]);
            seconds = seconds - (time_units[i][0] * occurences_of_unit);
            breakdown[time_units[i][1]] = occurences_of_unit;
        }
        return breakdown;
    };

    const render_date = (date_format, seconds_difference) => {
        var breakdown = get_time_breakdown(seconds_difference);
        var time_ago_text = date_format.text.replace(/\$(\w+)/g, function () {
            return breakdown[arguments[1]];
        });
        return depluralize_time_ago_text(time_ago_text, breakdown);
    };

    const depluralize_time_ago_text = (time_ago_text, breakdown) => {
        for (var i in breakdown) {
            if (breakdown[i] == 1) {
                var regexp = new RegExp("\\b" + i + "\\b");
                time_ago_text = time_ago_text.replace(regexp, function () {
                    return arguments[0].replace(/s\b/g, '');
                });
            }
        }
        return time_ago_text;
    };

    // Initialize method
    const init = () => {
        newsdate.forEach(e => {
            let tense = 'past';
            e.innerHTML = humanized_time_span(e.dataset.dateParution);
        });


    };

    return {
        init: init
    };
})();

module.exports = FormatHumanDate;


// source script: https://github.com/GrouchPotato/js_humanized_time_span
