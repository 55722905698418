/**
* @param {String} name String
* @param {Node} scope The DOM scope of the query else it will be the document
* @return {String} Value of the get parameter
*/

module.exports = (name, scope) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(decodeURIComponent(location.search.replace(/\+/g," ")).replace(/&&/,"%26%26"));
        return results === null ? '' : decodeURIComponent(results[1]);
};
