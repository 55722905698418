// @module pagination
const Pagination = (function() {
  const pagination = document.querySelector('[data-js-pagination=""]');

  const init = () => {
    if (pagination) {
      const pages = [].slice.call(pagination.querySelectorAll('[data-js-pagination="page"]'));

      if (pages.length > 1) {
        let selector = pagination.querySelector('select');

        pages.forEach((p,i) => {
          let isSelected = p.querySelector('[aria-current="page"]') ? ' selected=""' : '';

          selector.insertAdjacentHTML('beforeend',`<option ${isSelected} value="${p.dataset.jsUrl}">${p.dataset.jsPage}</option>`);
          selector.addEventListener('change', evt => {
            window.location =  evt.target.value;
          });
        });

        pagination.querySelector('[data-js-pagination="selector"]').hidden = false;
      }
    }

    return;
  };

  return {
    init : init
  };
})();

module.exports = Pagination;
