// @module toggletip
const SearchHistory = (function() {
    const maxItem = 10;

    //search history stack
    var currentHistory;
    //search results
    var currentNav = {
        'prev': false,
        'next': false
    };

    /**
     * Stack initialization
     * Adds the tx_solr[q] to history
     */
    const init = () => {
        //get the search history (Used in the search bar)
        currentHistory = _getHistory();
        if (!currentHistory) {
            currentHistory = [];
            window.localStorage.setItem("searchhistory", currentHistory);
        }

        // Si une recherche a été effectuée
        const resultats = document.querySelector('.search-results[data-js-requeteActuelle]');
        if ( resultats && resultats.dataset['jsRequeteactuelle'] ) {
            document.getElementById("searchBannerField").value = resultats.dataset['jsRequeteactuelle'];
            _addToHistory(resultats.dataset['jsRequeteactuelle']);
            _populateSearchNav(document, "search");
        }

        //nav rendering
        let navObj = document.querySelector('[data-js-sequential="nav"]');
        if ((navObj) && (sessionStorage.getItem('searchquery'))) {
            _renderNav();
        } else if (navObj){
            navObj.remove();
        }
    };

    const _getHistory = () => {
        if ((localStorage.getItem('searchhistory') === null) || (localStorage.getItem('searchhistory') == "")) {
            //if (localStorage.getItem('searchhistory') === null) {
            return [];
        }
        return JSON.parse(localStorage.getItem('searchhistory'));;
    };

    const _getSearchNav = () => {
        if (sessionStorage.getItem('searchnav') === null) {
            return [];
        }
        return JSON.parse(sessionStorage.getItem('searchnav'));;
    };
    /**
     * Add an item to the search history until the maxItem is reach.
     * Then first element will be remove.
     */
    const _addToHistory = (q) => {
        currentHistory.forEach(function(item, i) {
            if (item == q) {
                currentHistory.splice(i, 1);
            }
        });
        currentHistory.unshift(q);
        if (currentHistory.length > maxItem) {
            currentHistory.splice(-1, 1);
        }


        window.localStorage.setItem("searchhistory", JSON.stringify(currentHistory));
    };

    /**
     * Creates a list of search result.
     */
    const _populateSearchNav = (container = document, mode = "search") => {

        currentNav = [];
        let results = [].slice.call(container.querySelectorAll('.search-results__item .result'));

        results.forEach(function(item) {

            currentNav.push({
                title: item.dataset.title,
                url: item.dataset.url
            });
        });

        if (mode == "search") {
            window.sessionStorage.setItem("searchnav", JSON.stringify(currentNav));
            window.sessionStorage.setItem("nolessResult", 0);
            window.sessionStorage.setItem("nomoreResult", 0);
            window.sessionStorage.setItem("searchquery", window.location.pathname + window.location.search);
        }
        return currentNav;
    };

    const _getNav = () => {
        let currentLocation = location.pathname;
        currentNav = _getSearchNav();

        let prev = false;
        let next = false;
        let found = false;
        let page = _getCurrentPage();


        currentNav.forEach(function(item, i) {
            if (item.url == currentLocation) {
                found = true;
                if (i > 0) {
                    prev = currentNav[(i - 1)];
                }
                if (i < (currentNav.length - 1)) {
                    next = currentNav[i + 1];
                }
                if((i == 0) && (page == 1)) {
                    sessionStorage.setItem("nolessResult","1");
                }
                
            }
        });

        if (!found) {
            return false;
        }


        if ((next === false) && window.sessionStorage.getItem("searchquery") && (sessionStorage.getItem("nomoreResult") != "1")) {
            next  = {url:'#',title: '<img alt="téléchargement" src="/typo3conf/ext/tm_core/Resources/Public/src/images/chargement.gif" />'};
            _fetchResult("more");
        }
        if ((prev === false) && window.sessionStorage.getItem("searchquery") && (sessionStorage.getItem("nolessResult") != "1")) {
            prev  = {url:'#',title: '<img alt="téléchargement" src="/typo3conf/ext/tm_core/Resources/Public/src/images/chargement.gif" />'};
            _fetchResult("less");
        }

        return {
            'prev': prev,
            'next': next
        };
    };

    const _getCurrentPage = () => {

        let uri = sessionStorage.getItem('searchquery');
        let page = 1;
        let pageRe = uri.match("tx_solr%5Bpage%5D=([0-9]+)");
        if ((pageRe !== null) && (pageRe[1] != undefined)) {
            page = pageRe[1];
        }
        return page;
    }
    /**
     * Redo the search adding or substracting a page from the previous search.
     */
    const _fetchResult = (direction) => {
        let uri = sessionStorage.getItem('searchquery');

        let httpRequest = new XMLHttpRequest();
        httpRequest.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                var container = document.implementation.createHTMLDocument().documentElement;
                container.innerHTML = this.responseText;
                let tmpNav = _populateSearchNav(container, "ajax");
                if (tmpNav.length == 0) {
                    sessionStorage.setItem("no" + direction + "Result", 1);
                } else {
                    let currentNav = _getSearchNav();
                    let newNav = [];
                    if (direction == "more") {
                        newNav = currentNav.concat(tmpNav);
                    } else {
                        newNav = tmpNav.concat(currentNav);
                    }
                    window.sessionStorage.setItem("searchnav", JSON.stringify(newNav));
                    window.sessionStorage.setItem("searchquery", uri);

                }
                _renderNav();


            }
        };
        let page = _getCurrentPage();
        if (page == 1) {
            sessionStorage.setItem("nolessResult", 1);
        }
        if (direction == "more") {
            page++;
        } else {
            page--;
        }
        if (page > 0) {

            uri = uri.replace(/&?tx_solr%5Bpage%5D=[0-9]+/, "");
            uri = uri.replace(/&?tx_solr%5BresultsPerPage%5D=[0-9]+/, "");
            uri = uri + "&tx_solr%5Bpage%5D=" + page;
            httpRequest.open('GET', uri, true);
            httpRequest.send();
        }

    };

    const _renderNav = () => {
        const nav = document.querySelector('[data-js-sequential="nav"]');
        if (nav) {
            const navList = nav.querySelector('[data-js-sequential="list"]');

            let btns = _getNav();

            if (btns['next'] || btns['prev']) {
                let showPrev = (!btns['prev'] ? 'visuallyhidden' : '');
                let showNext = (!btns['next'] ? 'visuallyhidden' : '');

                let prevBtn = `
            <li class=" ${showPrev}">
                <a href="${btns.prev.url}" rel="prev">
                    <span>
                        <svg aria-hidden="true" height="16" role="img" width="19">
                            <use href="#ico_flechegauche"></use>
                        </svg>
                        Résultat précédent
                    </span><br>
                    ${btns.prev.title}
                </a>
            </li>
        `;
                let nextBtn = `
            <li class=" ${showNext}">
                <a href="${btns.next.url}" rel="next">
                    <span>
                        Résultat suivant
                        <svg aria-hidden="true" height="16" role="img" width="19">
                            <use href="#ico_flechedroite"></use>
                        </svg>
                    </span>
                    <br>
                    ${btns.next.title}
                </a>
            </li>
        `;
                let navHtml = `
                ${prevBtn}
                ${nextBtn}
        `;


                nav.hidden = false;
                navList.innerHTML = navHtml;
            } else {
                nav.parentNode.removeChild(nav);
            }
        }

        return;
    };

    const getHistorySorted = () => {
        return currentHistory;
    };

    const deleteIndex = (i) => {
        currentHistory.splice(i, 1);
        window.localStorage.setItem("searchhistory", JSON.stringify(currentHistory));
    };


    return {
        init: init,
        deleteIndex: deleteIndex,
        getHistorySorted: getHistorySorted
    };
})();

module.exports = SearchHistory;
