// @param {Node} `el` The base element
// @param {String} `eventType` The event type or name
// @param {Object} `eventData` The detail property. Data associated with the event
// @usage triggerCustomEvent(element, 'catdown', [myBigFatData, 'Hello Goodbye!']);
module.exports = (el, eventType, eventData) => {
    let customEvent;

    if (Event) {
        if (typeof Event.prototype.constructor === 'function') {
            customEvent = new Event(eventType, {
                bubbles: true
            });

            customEvent.detail = eventData;
        } else {
            customEvent = document.createEvent('CustomEvent');
            customEvent.initCustomEvent(eventType, true, true, eventData);
        }

        // Dispatch the event.
        return el.dispatchEvent(customEvent);
    }

    return null;
};
