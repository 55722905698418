// @module sondage
const Sondage = (function() {
  const sondage = document.querySelector('[data-js-sondage=""] form');

  const init = () => {
    if (sondage) {
      const radioInputs = [].slice.call(sondage.elements['tx_powermail_pi1[field][satisfaction]']);
      const radiosFieldset = sondage.querySelector('fieldset');
      const radiosSiblings = [].slice.call(radiosFieldset.parentNode.children).filter(sibling => sibling !== radiosFieldset);

      radioInputs[0].checked = false;
      radioInputs[1].checked = false;

      if (radiosFieldset.contains(radioInputs[0])
          && (radioInputs[0].checked === false && radioInputs[1]).checked === false) {
        radiosSiblings.forEach(sibling => sibling.hidden = true);
      }

      radioInputs.forEach(radio => {
        radio.addEventListener('change', evt => {
          if (evt.currentTarget.checked === true) {
            radiosSiblings.forEach(sibling => sibling.hidden = false);
          }
        });
      });
    }

    return;
  };

  return {
    init : init
  };
})();

module.exports = Sondage;
