// Share content to social media & co.
const Share = (function() {
  let share = document.querySelector('[data-js-share=""]');

  const copyURL = () => {
    const notifier = require('./notifier.js').init;
    const button = share.querySelector('[data-js-share="copyurl"]');
    const wrapper = share.parentNode;
    const feedback = button.getAttribute('data-js-status');

    button.addEventListener('click', evt => {
      const textarea = document.createElement('textarea');

      textarea.className = 'visuallyhidden';
      textarea.setAttribute('aria-hidden', 'true');
      textarea.setAttribute('readonly', 'true');
      textarea.innerHTML = window.location.href;

      // Append the hidden textarea
      share.appendChild(textarea);
      textarea.focus();
      textarea.select();

      // Copy execution
      document.execCommand('copy');

      // Supprimer après utilisation
      textarea.remove();

      // Display the feedback
      notifier(feedback, { closable: true, type: 'positive', duration: 5000 });
    });

    return;
  };

  const moveAccordingToTheViewPort = () => {
    const desktopContainer = document.querySelector('[data-js-share="desktop container"]');
    const mobileContainer = document.querySelector('[data-js-share="mobile container"]');

    if (window.matchMedia('screen and (min-width: 80rem)').matches
        && desktopContainer
        && share.parentNode === mobileContainer) {
      share = document.querySelector('[data-js-share=""]');

      desktopContainer.insertAdjacentElement('beforeend', share);
    } else if (!window.matchMedia('screen and (min-width: 80rem)').matches
               && mobileContainer
               && share.parentNode === desktopContainer) {
      share = document.querySelector('[data-js-share=""]');

      mobileContainer.insertAdjacentElement('beforeend', share);
    }

    return;
  };

  const init = () => {
    if (share) {
      let shareTitle;

      if (document.title.indexOf('|') !== -1) {
        shareTitle = document.title.substr(0, (document.title.indexOf('|') - 1));
      } else {
        shareTitle = document.title;
      }

      const facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.URL)}`;
      const x = `https://x.com/intent/tweet?url=${encodeURIComponent(document.URL)}&text=${encodeURIComponent(shareTitle)}%0a`;
      const linkedin = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(document.URL)}&title=${encodeURIComponent(shareTitle)}`;
      const email = `mailto:?body=${encodeURIComponent(document.URL)}&subject=${encodeURIComponent(shareTitle)}`;

      // Move the share menu
      moveAccordingToTheViewPort();

      window.addEventListener('resize', evt => {
        requestAnimationFrame(moveAccordingToTheViewPort);
      });

      // Set href attrs with prefill infos
      share.querySelector('[href*="mailto:"]').href = email;
      share.querySelector('[href*="facebook.com"]').href = facebook;
      share.querySelector('[href*="x.com"]').href = x;
      share.querySelector('[href*="linkedin.com"]').href = linkedin;

      // Unhidden print and copyurl buttons
      share.querySelector('[data-js-share="copyurl"]').parentNode.hidden = false;
      share.querySelector('[data-js-share="print"]').parentNode.hidden = false;

      // Set the copy URL functionnality
      copyURL();
    }

    return;
  };

  return {
    init : init
  };
})();

module.exports = Share;
