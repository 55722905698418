// Facets module
const Facets = (function() {
  const facetsSidebar = document.querySelector('[data-js-facets="sidebar"]');
  const isSmallScreen = require('../utils/is-smallscreen.js');
  const collapsible = require('../utils/collapsible.js');
  let listeDomainesEstReduite = true;

  const setCloseButton = () => {
    const close = document.createElement('button');

    close.className = 'facets__close';
    close.dataset.jsCollapsible = 'close';
    close.innerHTML = '<span class="visuallyhidden">Fermer le panneau de filtres</span><svg aria-hidden="true" role="img" height="14px" width="14px"><use xlink:href="#ico_close"></use></svg>';
    close.type = 'button';

    return close;
  };

  const setFacetsCollapsible = () => {
    const sidebar = document.querySelector('[data-js-facets="sidebar"]');
    const container = sidebar.parentNode;
    const facets = sidebar.querySelector('[data-js-facets="facets"]');

    if (!sidebar.querySelector('[data-js-collapsible="button"]')) {
      facets.hidden = true;
      facets.insertAdjacentElement('afterbegin', setCloseButton());

      collapsible(
        sidebar.querySelectorAll('[data-js-facets="title"]'),
        sidebar.querySelectorAll('[data-js-facets="facets"]'),
        true
      );

      sidebar.addEventListener('collapse', evt => {
        if (evt.detail.isExpanded === true) {
          document.body.classList.add('facets-overflow');
        } else {
          document.body.classList.remove('facets-overflow');
        }

        return;
      });
    }

    return;
  };

  const setFacetsSwitchs = () => {
    const switchBDL = facetsSidebar.querySelector('#switch_bdl_only');
    const switchGDT = facetsSidebar.querySelector('#switch_gdt_only');
    const switchTerme = facetsSidebar.querySelector('#terme-facet');
    const switchTermeComplet = facetsSidebar.querySelector('#tcomplet-facet');

    if (switchBDL) {
      switchBDL.addEventListener('onswitch', evt => {
        return window.location = evt.currentTarget.dataset.jsUrl;
      });
    }

    if (switchGDT) {
      switchGDT.addEventListener('onswitch', evt => {
        return window.location = evt.currentTarget.dataset.jsUrl;
      });
    }

    if (switchTerme) {
      switchTerme.addEventListener('onswitch', evt => {
        return window.location = evt.currentTarget.dataset.jsUrl;
      });

      if (switchTerme.value == 1 && switchTermeComplet) {
        if ( switchTermeComplet.value == 1 ) {
          // Si les deux sont actif en même temps parce que l'usager a cliqué l'étiquette pour retirer le filtre "Élargir au texte intégral : Non"
          window.location = switchTermeComplet.dataset.jsUrl;
        }

        switchTermeComplet.disabled = true;
        switchTermeComplet.title = 'Ne peut être activé en même temps que « Élargir au texte intégral ».' ;     
      }
    }

    if (switchTermeComplet) {
      switchTermeComplet.addEventListener('onswitch', evt => {
        return window.location = evt.currentTarget.dataset.jsUrl;
      });

      if (switchTermeComplet.value == 1 && switchTerme) {
        switchTerme.disabled = true;
        switchTerme.title = 'Ne peut être activé en même temps que « Termes exacts ».' ;     
      }
    }

    return;
  };

  const sauvegarderPositionScroll = () => {
    sessionStorage.setItem( 'posY', window.scrollY );
    sessionStorage.setItem( 'listeDomainesEstReduite', listeDomainesEstReduite );
  }

  const hideTooManyFilters = qty => {
    const getClosest = require('../utils/get-closest.js');

    if ( sessionStorage.getItem('listeDomainesEstReduite') == 'false' ) {
      listeDomainesEstReduite = false;
    }
    sessionStorage.removeItem('listeDomainesEstReduite');


    [].slice.call(facetsSidebar.querySelectorAll('[data-js-facets="gdt"] [data-js-facets="facet"]')).forEach(facet => {
      const facetItem = facet.querySelectorAll('[data-facet-item-value]');

      // If the quantity of filters is over the qty argument
      if (facetItem.length >= qty) {
        const stopper = facetItem.item(qty - 1);
        const button = document.createElement('button');
        const buttonText = ["Voir tous les filtres", "Voir moins de filtres"];

        let ancestor = getClosest(stopper.parentNode, 'li');

        // Add classes to mark where the quantity of filter is exceeded
        stopper.classList.add('stopper');
        if ( listeDomainesEstReduite ) {
          stopper.classList.add('stopper--active');
        }

        // If there's is some ancestor add classes for them too
        while (ancestor !== null) {
          ancestor.classList.add('stopper-ancestor', 'stopper--active');
          ancestor = getClosest(ancestor.parentNode, 'li');
        }

        // Create a button to toggle the visibily of the filters
        button.innerHTML = listeDomainesEstReduite?buttonText[0]:buttonText[1];
        button.classList.add('facets__visibility', 'button-link-like');
        button.addEventListener('click', evt => {
          [].slice.call(facet.querySelectorAll('[class*="stopper"]'))
            .forEach(s => {
              listeDomainesEstReduite = s.classList.toggle('stopper--active');

              if (s.classList.contains('stopper--active')) {
                evt.currentTarget.innerHTML = buttonText[0];
              } else {
                evt.currentTarget.innerHTML = buttonText[1];
              }
            });
        });

        // if there's stopper in the facet insert the button
        if (facet.contains(stopper)) {
          facet.insertAdjacentElement('beforeend', button);
        }
      }
    });

    return;
  };

  const setBoutonEnregistrer = () => {
    const bouttonEnregistrer = facetsSidebar.querySelector('.facets-sidebar__enregistrer');
    const filtres = localStorage.getItem('filtresEnregistres');
    const tri = localStorage.getItem('triEnregistres');
    const parPage = localStorage.getItem('parPageEnregistre');
    const pres = localStorage.getItem('presentationEnregistree');
    const invFrEn = localStorage.getItem('invFrEnEnregistree');


    // Activer le bouton d'enregistrement
    if ( bouttonEnregistrer ) {
        // 1- S'il y a des filtres enregistrés -> bouton 'oublier'
        if ( filtres || tri || parPage || pres || invFrEn ) {
            bouttonEnregistrer.classList.add("plein");
            bouttonEnregistrer.querySelector("span").innerText = "Annuler la mémorisation";
            bouttonEnregistrer.title = "Revenir aux valeurs par défaut pour les prochaines recherches (aucun effet sur l’affichage des résultats de la recherche en cours).";
            bouttonEnregistrer.addEventListener('click', function() {
                localStorage.removeItem('filtresEnregistres');
                localStorage.removeItem('triEnregistres');
                localStorage.removeItem('parPageEnregistre');
                localStorage.removeItem('presentationEnregistree');
                localStorage.removeItem('invFrEnEnregistree');
                sessionStorage.removeItem('BDLCochee');
                sessionStorage.removeItem('GDTCoche');
            });

        } else {
            const filtresParDefaut = ["type_stringM:bdl","type_stringM:gdt","terme:1"];
            const parPageParDefaut = 25;
            const triParDefaut = "";
            const presParDefaut = "detail";
            const invFrEnParDefaut = "0";

            let filtresArray = [];
            // Obtenir un array du dataset, tenir compte du cas où les filtres ne sont pas en ordre numérique et où JSON.parse retourne un objet au lieu d'un array
            try { JSON.parse(bouttonEnregistrer.dataset.filtres, (cle,valeur) => {
              if ( typeof(valeur) === 'string') filtresArray.push(valeur);
            })
            } catch {}

        // 2- Si les filtres actuels sont ceux par défaut -> bouton enregistrer désactivé
            if ( bouttonEnregistrer.dataset.tri == triParDefaut &&
                (bouttonEnregistrer.dataset.parpage == "" || bouttonEnregistrer.dataset.parpage == parPageParDefaut) &&
                (bouttonEnregistrer.dataset.pres == "" || bouttonEnregistrer.dataset.pres == presParDefaut) &&
                (bouttonEnregistrer.dataset.invfren == "" || bouttonEnregistrer.dataset.invFrEn == invFrEnParDefaut) &&
                (filtresArray?.length == filtresParDefaut.length) && filtresArray.every( element => filtresParDefaut.includes(element) ) ) {
                bouttonEnregistrer.classList.add("inactif");
                bouttonEnregistrer.title = "Les valeurs actuelles sont celles attribuées par défaut. Si vous sélectionnez de nouvelles valeurs, il sera ensuite possible de les mémoriser.";
                bouttonEnregistrer.setAttribute("aria-disabled", "true");
                bouttonEnregistrer.querySelector("span").innerText = "Mémoriser les filtres";
                bouttonEnregistrer.removeAttribute("href");

        // 3- Sinon, bouton enregistrer actif
            } else {
                bouttonEnregistrer.classList.add("vide");
                bouttonEnregistrer.querySelector("span").innerText = "Mémoriser les filtres";
                bouttonEnregistrer.title = "Mémoriser toutes les valeurs sélectionnées et les appliquer aux prochaines recherches.";
                bouttonEnregistrer.addEventListener('click', function() {
                    // Enregistrer les filtres en local, remplacer les valeurs de la dernière recherche
                    localStorage.setItem('filtresEnregistres', this.dataset.filtres);
                    localStorage.setItem('triEnregistres', this.dataset.tri );
                    localStorage.setItem('parPageEnregistre', this.dataset.parpage );
                    localStorage.setItem('presentationEnregistree', this.dataset.pres );
                    localStorage.setItem('invFrEnEnregistree', this.dataset.invfren );
                    sessionStorage.removeItem('BDLCochee');
                    sessionStorage.removeItem('GDTCoche');

                });

            }
        }

        bouttonEnregistrer.removeAttribute('hidden');
    }
  }


  const init = () => {
    if (facetsSidebar) {
      setFacetsCollapsible();
      setFacetsSwitchs();
      setBoutonEnregistrer();
      hideTooManyFilters(5);

      facetsSidebar.querySelectorAll('header a').forEach(s => {
        s.addEventListener('click', sauvegarderPositionScroll);
      });

      // Si les facettes sont visible ( non mobile )
      if ( getComputedStyle(facetsSidebar.querySelector('[data-js-closable]') ).display == 'none' ) {
        facetsSidebar.querySelectorAll('.facets__switch').forEach(s => {
          s.addEventListener('click', sauvegarderPositionScroll);
        });
        facetsSidebar.querySelectorAll('.facets__list').forEach(s => {
          s.addEventListener('change', sauvegarderPositionScroll);
        });
        facetsSidebar.querySelectorAll('.switch-button button').forEach(s => {
          s.addEventListener('onswitch', sauvegarderPositionScroll);
        });
        facetsSidebar.parentElement.querySelectorAll('.search-results__controls select, .search-results__controls input').forEach(s => {
          s.addEventListener('change', sauvegarderPositionScroll);
        });
      }
    }

    return;
  };

  return {
    init : init
  };
})();

module.exports = Facets;
