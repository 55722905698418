// @module search banner
const SearchBanner = (function() {
    const banner = document.getElementById('searchBanner');
    const searchHistory = require('./searchhistory.js');
    const find = require('../utils/array-find.js');
    const matches = require('../utils/matches.js');
    const debounce = require('../utils/debounce.js');
    const getClosest = require('../utils/get-closest.js');
    const accentMap =  {
      ae: '(ae|æ|ǽ|ǣ)',
      oe: '(oe|œ)',
      a:  '(a|á|ă|ắ|ặ|ằ|ẳ|ẵ|ǎ|â|ấ|ậ|ầ|ẩ|ẫ|ä|ǟ|ȧ|ǡ|ạ|ȁ|à|ả|ȃ|ā|ą|ᶏ|ẚ|å|ǻ|ḁ|ⱥ|ã)',
      c:  '(c|ć|č|ç|ḉ|ĉ|ɕ|ċ|ƈ|ȼ)',
      e:  '(e|é|ĕ|ě|ȩ|ḝ|ê|ế|ệ|ề|ể|ễ|ḙ|ë|ė|ẹ|ȅ|è|ẻ|ȇ|ē|ḗ|ḕ|ⱸ|ę|ᶒ|ɇ|ẽ|ḛ)',
      i:  '(i|í|ĭ|ǐ|î|ï|ḯ|ị|ȉ|ì|ỉ|ȋ|ī|į|ᶖ|ɨ|ĩ|ḭ)',
      n:  '(n|ń|ň|ņ|ṋ|ȵ|ṅ|ṇ|ǹ|ɲ|ṉ|ƞ|ᵰ|ᶇ|ɳ|ñ)',
      o:  '(o|ó|ŏ|ǒ|ô|ố|ộ|ồ|ổ|ỗ|ö|ȫ|ȯ|ȱ|ọ|ő|ȍ|ò|ỏ|ơ|ớ|ợ|ờ|ở|ỡ|ȏ|ō|ṓ|ṑ|ǫ|ǭ|ø|ǿ|õ|ṍ|ṏ|ȭ)',
      u:  '(u|ú|ŭ|ǔ|û|ṷ|ü|ǘ|ǚ|ǜ|ǖ|ṳ|ụ|ű|ȕ|ù|ủ|ư|ứ|ự|ừ|ử|ữ|ȗ|ū|ṻ|ų|ᶙ|ů|ũ|ṹ|ṵ)',
      "'":  "('|’)",      
    };

    // History methods

    const setHistoryButton = () => {
        const historyButton = document.createElement('span');
        const historyButtonIco = '<svg aria-hidden="true" height="20px" role="image" width="22px"><use xlink:href="#ico_historique"></use></svg>';
        const historyButtonString = '<span class="visuallyhidden">Consulter l\'</span><span class="visuallyhidden--to-md">Historique</span><span class="visuallyhidden"> de recherche</span>';

        historyButton.classList.add('search-banner__history');
        historyButton.dataset.jsSearchBanner = 'history button';
        historyButton.innerHTML = historyButtonIco + historyButtonString;

        return historyButton;
    };

    const setResultButton = (result, search, form) => {
        const resultButton = document.createElement('button');
        const resultButtonIco = '<svg aria-hidden="true" height="20px" role="image" width="22px"><use xlink:href="#ico_historique"></use></svg>';
        const resultButtonString = result;

        resultButton.classList.add('search-banner__result');
        resultButton.innerHTML = resultButtonIco + resultButtonString;
        resultButton.setAttribute('type', 'button');

        resultButton.addEventListener('click', evt => {
            search.value = result;

            return form.submit();
        });

        return resultButton;
    };

    const setRemoveButton = (list, index) => {
        const removeButton = document.createElement('button');
        const removeButtonIco = '<svg aria-hidden="true" height="14px" role="image" width="14px"><use xlink:href="#ico_fermer"></use></svg>';
        const removeButtonString = '<span class="visuallyhidden">Retirer ce résulat de l\'historique.</span>';

        removeButton.setAttribute('type', 'button');
        removeButton.innerHTML = removeButtonString + removeButtonIco;
        removeButton.classList.add('search-banner__remove');

        removeButton.addEventListener('click', evt => {
            searchHistory.deleteIndex(index);
            // Remove the parentNode of the current delete button
            evt.currentTarget.parentNode.parentNode.removeChild(evt.currentTarget.parentNode);
        });

        return removeButton;
    };

    const setHistoryList = () => {
        const historyList = document.createElement('ol');

        historyList.classList.add('search-banner__list');
        historyList.dataset.jsSearchBanner = 'history list';
        historyList.setAttribute("aria-label", "Liste de l'historique de vos recherches");
        historyList.setAttribute("hidden", "");

        return historyList;
    };

    const setHistoryItems = (history, search, form, historyList) => {
        history.forEach((h, i) => {
            const historyItem = document.createElement('li');

            historyItem.classList.add('search-banner__item');
            historyItem.appendChild(setResultButton(h, search, form));
            historyItem.appendChild(setRemoveButton(historyList, i));
            historyList.appendChild(historyItem);
        });

        return;
    };

    // Autocomplete methods

    const setAutoCompleteRequest = (input, list, form) => {
        let httpRequest = new XMLHttpRequest();

        list.innerHTML = '';

        if (input.value.length >= 3) {
            httpRequest.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    let responseArr = JSON.parse(this.responseText);

                    if (responseArr.length > 0) {
                        list.hidden = false;
                        input.setAttribute('aria-expanded', 'true');

                        setAutoCompleteItems(responseArr, input, form, list);
                    } else {
                        list.hidden = true;
                        input.setAttribute('aria-expanded', 'false');
                    }
                }
            };

            httpRequest.open('GET', '/solrSuggest.php?type=7384&tx_solr[queryString]=' + input.value, true);
            httpRequest.send();

        }
    };
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    const checkHighlightList = (str, queries) => {
      let accentRegex = new RegExp(Object.keys(accentMap).join('|'), 'g');
      const queryRegex = new RegExp(queries.map(q => {
        return escapeRegExp(q).toLowerCase().replace(accentRegex, m => {
          return accentMap[m] || m;
        });
      }).join('|'), 'gi');
      return str.replace(queryRegex, m => `<mark>${ m }</mark>`);
    }


    const setAutoCompleteButton = (result, input, form, index) => {
        const resultButton = document.createElement('button');
        const resultButtonIco = '<svg aria-hidden="true" class="ico-recherche" height="19px" role="image" width="19px"><use xlink:href="#ico_recherche"></use></svg>';
        //const resultString = result.title.replace(input.value, `<mark>${input.value}</mark>`);
        const resultString = checkHighlightList(result.title,[input.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "")]);
        const resultButtonString = `${resultButtonIco}<span class="visuallyhidden">Lancer la recherche pour </span><span>${resultString}</span>`;


        resultButton.classList.add('search-banner__result');
        resultButton.id = 'searchBannerResultOption_' + index;
        resultButton.innerHTML = resultButtonString;
        resultButton.setAttribute('type', 'button');
        resultButton.setAttribute('role', 'option');

        resultButton.addEventListener('click', evt => {
            input.value = result.title.replace("&nbsp;"," ").replace(" : "," ");
            console.log(input.value);

            return form.submit();
        });


        resultButton.addEventListener('focus', evt => {
            return input.setAttribute('aria-activedescendant', evt.currentTarget.id);
        });

        resultButton.addEventListener('blur', evt => {
            return input.removeAttribute('aria-activedescendant');
        });

        return resultButton;
    };

    const setAutoCompleteList = (champRecherche) => {
        const autocompleteList = document.createElement('ol');

        autocompleteList.classList.add('search-banner__list');
        autocompleteList.dataset.jsSearchBanner = 'autocomplete list';
        autocompleteList.id = 'searchBannerAutocompleteList';
        autocompleteList.setAttribute("aria-label", "Liste de suggestion de recherche");
        autocompleteList.setAttribute("hidden", "");

        // When escaped
        document.addEventListener('keydown', evt => {
            if ((evt.keyCode || evt.which) === 27
                && !autocompleteList.hidden) {
                evt.preventDefault();
                champRecherche.setAttribute('aria-expanded', 'false');
                return autocompleteList.setAttribute("hidden", "");
            }

            return null;
        });

        // When clicked outside
        document.body.addEventListener('click', evt => {
            if (!getClosest(evt.target, '[data-js-search-banner="wrap"]')
                && !getClosest(evt.target, '[data-js-search-banner="wrap"]')
                && !autocompleteList.hidden) {
              evt.preventDefault();
              champRecherche.setAttribute('aria-expanded', 'false');
              return autocompleteList.setAttribute("hidden", "");
            }

            return null;
        });

        return autocompleteList;
    };

    const setAutoCompleteItems = (results, input, form, list) => {
        results.forEach((h, i) => {
            const autocompleteItem = document.createElement('li');

            autocompleteItem.classList.add('search-banner__item');
            autocompleteItem.appendChild(setAutoCompleteButton(h, input, form, i));

            list.appendChild(autocompleteItem);
        });

        return;
    };

    // Other methods

    const setSearchField = (field, list, form) => {
        field.setAttribute('aria-autocomplete', 'list');
        field.setAttribute('aria-controls', list.id);
        field.setAttribute('role', 'combobox');
        field.setAttribute('aria-expanded', 'false');
        field.addEventListener('input', debounce(evt => setAutoCompleteRequest(evt.target, list, form), 250));

        // Navigate through the suggestions with the up & down arrows
        field.addEventListener('keydown', evt => {
            if (evt.keyCode == 40 && !list.hidden) {
                evt.preventDefault();

                list.querySelector('[role="option"]').focus();
            }
        });

        list.addEventListener('keydown', evt => {
            let buttonsArr;
            let hasActiveDescendant;

            if (evt.keyCode == 40 && matches(evt.target, '[role="option"]')) {
                evt.preventDefault();

                buttonsArr = [].slice.call(list.querySelectorAll('button'));
                hasActiveDescendant = buttonsArr.some(button => button === document.activeElement);

                if (hasActiveDescendant && (buttonsArr.indexOf(evt.target)) < (buttonsArr.length - 1)) {
                    buttonsArr[buttonsArr.indexOf(evt.target) + 1].focus();
                }
            }

            if (evt.keyCode == 38
                && matches(evt.target, '[role="option"]')) {
                evt.preventDefault();

                buttonsArr = [].slice.call(list.querySelectorAll('button'));
                hasActiveDescendant = buttonsArr.some(button => button === document.activeElement);

                if (hasActiveDescendant && (buttonsArr.indexOf(evt.target)) > 0) {
                    buttonsArr[buttonsArr.indexOf(evt.target) - 1].focus();
                } else {
                    field.focus();
                }
            }
        });

        return;
    };

    const setupCheckSession = (formulaire, cochesEnregistrees) => {
        let bdl,gdt;

        formulaire.addEventListener('submit', function(ev) {
            if ( this.elements.searchBannerField.value.trim() == '' || 
               ( !this.elements.searchBannerBDL.checked && !this.elements.searchBannerGDT.checked )) {
                ev.preventDefault();
                return false;
            }
            setSessionField('GDTCoche', formulaire.querySelector('#searchBannerGDT').checked);
            setSessionField('BDLCochee', formulaire.querySelector('#searchBannerBDL').checked);
            return true;
        });

        // Coches de session ont priorité sur les valeurs enregistrées : si on viens d'utiliser le champ de recherche dans le bandeau
        if (getSessionField('BDLCochee')) {
            bdl = (getSessionField('BDLCochee')=='false'?false:true);
        } else {
            bdl = cochesEnregistrees.bdl;
        }
        if (getSessionField('GDTCoche')) {
            gdt = (getSessionField('GDTCoche')=='false'?false:true);
        } else {
            gdt = cochesEnregistrees.gdt;
        }

        formulaire.querySelector("#searchBannerBDL").checked = bdl;
        formulaire.querySelector("#searchBannerGDT").checked = gdt;
        formulaire.querySelector("#searchBannerGDTTerme").checked = cochesEnregistrees.gdtTerme;
    }

    const filtresEnregistres = (formulaire) => {
        let filtres = getLocalStorageField('filtresEnregistres');
        const tri = getLocalStorageField('triEnregistres');
        const parPage = getLocalStorageField('parPageEnregistre');
        const presentation = getLocalStorageField('presentationEnregistree');
        const invFrEn = getLocalStorageField('invFrEnEnregistree');

        let bdl=true, gdt=true, gdtTerme=true;   // cases à cocher du formulaire de recherche, actives par défaut

        // Activer les filtres enregistrés
        if ( filtres ) {
            try {
                filtres = JSON.parse(filtres);
                bdl=false; gdt=false; gdtTerme=false;

                for ( let i in filtres ) {
                    const filtre = filtres[i];
                    switch ( filtre ) {
                    case 'type_stringM:bdl':
                        bdl=true;
                        break;
                    case 'type_stringM:gdt':
                        gdt=true;
                        break;
                    case 'terme:1':
                        gdtTerme=true;
                        break;
                    default:
                        ajouterValeurEnregistree( formulaire, 'tx_solr[filter][]', filtre);
                    }
                }
            } catch(e) {
                supprimerLocalStorageField("filtresEnregistres");
            }
        }

        // Activer le tri enregistré
        if ( tri ) {
            ajouterValeurEnregistree( formulaire, 'tx_solr[sort]', tri);
        }
        // Activer le nombre de résultats par page enregistré
        if ( parPage ) {
            ajouterValeurEnregistree( formulaire, 'tx_solr[resultsPerPage]', parPage);
        }
        // Activer le nombre de résultats par page enregistré
        if ( presentation ) {
            ajouterValeurEnregistree( formulaire, 'tx_solr[pres]', presentation);
        }

        // Activer le nombre de résultats par page enregistré
        if ( invFrEn ) {
            ajouterValeurEnregistree( formulaire, 'tx_solr[invFrEn]', invFrEn);
        }




        return {'bdl':bdl, 'gdt':gdt, 'gdtTerme':gdtTerme};
    }

    const ajouterValeurEnregistree = ( formulaire, nom, valeur ) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', nom);
        input.setAttribute('value', valeur );
        formulaire.appendChild( input );
    }

    const getLocalStorageField = (fieldName) => {
        return localStorage.getItem(fieldName);
    }
    const setLocalStorageField = (fieldName, fieldValue) => {
        return localStorage.setItem(fieldName, fieldValue);
    }
    const supprimerLocalStorageField = (fieldName) => {
        return localStorage.removeItem(fieldName);
    }
    const getSessionField = (fieldName) => {
        return sessionStorage.getItem(fieldName);
    }
    const setSessionField = (fieldName, fieldValue) => {
        return sessionStorage.setItem(fieldName, fieldValue);
    }
    const supprimerSessionField = (fieldName) => {
        return sessionStorage.removeItem(fieldName);
    }

    const init = () => {
        if (banner) {
            const collapsible = require('../utils/collapsible.js');
            const searchWrap = banner.querySelector('[data-js-search-banner="wrap"]');
            const searchForm = banner.querySelector('[name="search"]');
            const searchInput = banner.querySelector('#searchBannerField');
            let history;
            let cochesEnregistrees;

            cochesEnregistrees = filtresEnregistres(searchForm);
            setupCheckSession(searchForm, cochesEnregistrees);

            searchInput.insertAdjacentElement('afterend', setAutoCompleteList(searchInput));
            setSearchField(searchInput, banner.querySelector('[data-js-search-banner="autocomplete list"]'), searchForm);

            searchWrap.insertAdjacentElement('beforeend', setHistoryButton());
            searchWrap.insertAdjacentElement('beforeend', setHistoryList());

            collapsible(
                banner.querySelectorAll('[data-js-search-banner="history button"]'),
                banner.querySelectorAll('[data-js-search-banner="history list"]'),
                true
            );

            /*
             * https://hub.toumoro.com/issues/9220#note-9
            if (document.querySelector("body.recherche")) {
                if (decodeURIComponent(window.location.href).indexOf("type_stringS:bdl") == -1) {
                    document.getElementById("searchBannerBDL").checked = false;
                }
                if (decodeURIComponent(window.location.href).indexOf("type_stringS:gdt") == -1) {
                    document.getElementById("searchBannerGDT").checked = false;
                }
            }
           */
            banner.querySelector('[data-js-search-banner="history list"]').addEventListener('collapse', evt => {
                history = searchHistory.getHistorySorted();
                // Empty the list
                evt.currentTarget.innerHTML = '';
                // Fill the list
                setHistoryItems(history, searchInput, searchForm, evt.currentTarget);

                return;
            });

            return;
        }
    };

    return {
        init: init
    };
})();

module.exports = SearchBanner;
