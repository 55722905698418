/**
 * Retourne une chaine filtrée de ses accents, ligatures et différence de casse pour faire des comparaisons.
 * @param  {String} Chaîne d'origine
 * @return {String} Chaîne de retour
 */
module.exports = chaine => {
    const remplacements = {
        '’': '\'',
        ' ': ' ',
        'Æ': 'AE',
        'Œ': 'OE'
    };
    let retour = chaine.trim().toUpperCase().replace(/[’ ÆŒ]/g, m => remplacements[m]);

    // Pas encore très bien supporté
    if ( typeof String.prototype.normalize === "function" ) {
      retour = retour.normalize("NFKD").replace(/\p{Diacritic}/gu, "");
    }

    return retour;
};
