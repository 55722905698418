const ScrollTop = (function() {
  const buttonscrollTop = document.querySelector('[data-js-scrolltop=""]');

  const init = () => {
    const isElement = require('../utils/is-element.js');
    const toggleScrollTo = () => {
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      if (window.scrollY > viewportHeight) {
        buttonscrollTop.removeAttribute('hidden', '');
      } else {
        buttonscrollTop.setAttribute('hidden', '');
      }

      return;
    };

    if (isElement(buttonscrollTop)) {
      toggleScrollTo();

      return window.addEventListener('scroll', evt => {
        requestAnimationFrame(toggleScrollTo);
      });
    }

    return null;
  };

  return {
    init : init
  };
})();

module.exports = ScrollTop;
