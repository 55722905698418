// Dialog Module
// @usage
// dialog(document.queryselector('#myDialogTrigger'), document.queryselector('#myDialogContent')[, true]);
const Dialog = (function() {
  const triggerCustomEvent = require('../utils/trigger-custom-event.js');
  const getClosest = require('../utils/get-closest.js');
  const matches = require('../utils/matches.js');

  const setDialogTrigger = (trigger, uniqueID) => {
    // Add an id attribute if there's none
    if (trigger.id === "") {
      trigger.id = `dialogTrigger_${uniqueID}`;
    }

    return;
  };

  // Create the dialog element
  const setDialogElement = (trigger, content, uniqueID) => {
    const dialogElement = document.createElement('div');

    dialogElement.classList.add(
      'dialog',
      // Add a modifier class for custom styling
      `${(content.classList[0] ? content.classList[0] + '-dialog' : 'dialog--' + content.id)}`
    );
    dialogElement.setAttribute('aria-labelledby', trigger.id);
    dialogElement.setAttribute('aria-hidden', 'true');
    dialogElement.setAttribute('hidden', '');
    dialogElement.setAttribute('role', 'dialog');
    dialogElement.setAttribute('tabindex', '0');

    // Add an id attribute if there's none
    if (dialogElement.id === "") {
      dialogElement.id = `dialogModal_${uniqueID}`;
    }

    return dialogElement;
  };

  // Set dialog's close button
  const setDialogCloseButton = (dialog, trigger) => {
    const closeButton = document.createElement('button');
    const closeButtonIco = '<svg aria-hidden="true" role="img" class="ico-close"><use xlink:href="#ico_close"></use></svg>';
    const closeButtonText = '<span class="visuallyhidden">Fermer </span>';

    closeButton.classList.add('dialog__close', 'button-reset');
    closeButton.id = 'dialogClose';
    closeButton.innerHTML = closeButtonText + closeButtonIco;
    closeButton.setAttribute('type', 'button');
    closeButton.addEventListener('click', evt => {
      if (!dialog.hidden) {
        return toggleDialog(dialog, trigger, false);
      }

      return null;
    });

    return closeButton;
  };

  // Set dialog's content
  const setDialogContent = (content, dialog, trigger) => {
    content.appendChild(setDialogCloseButton(dialog, trigger));
    content.classList.add('dialog__content');

    return dialog.appendChild(content);
  };

  // Set visibility state as *visible*
  // @return node with different attributes
  const setDialogAsVisible = (dialog, trigger, scrollbar) => {
    dialog.removeAttribute('hidden');
    dialog.setAttribute('aria-hidden', 'false');

    // Give the focus to the dialog's close button
    if (dialog.querySelector('#dialogClose')) {
      dialog.querySelector('#dialogClose').focus();
    }

    // Remove the scrollbar
    if (!scrollbar) {
      document.body.style.overflow = 'hidden';
    }

    return;
  };

  // Set visibility state as *hidden*
  // @return node with different attributes
  const setDialogAsHidden = (dialog, trigger) => {
    dialog.setAttribute('hidden', '');
    dialog.setAttribute('aria-hidden', 'true');

    // Return the focus to the trigger
    if (!matches(trigger, '.visuallyhidden')) {
      trigger.focus();
    }

    // Restore the body's scrollbar
    document.body.style.overflow = 'auto';

    return;
  };

  const toggleDialog = (dialog, trigger, scrollbar) => {
    if (dialog.hidden) {
      // Show the dialog
      setDialogAsVisible(dialog, trigger, scrollbar);
    } else {
      // Hide the dialog
      setDialogAsHidden(dialog, trigger);
    }

    triggerCustomEvent(dialog, 'dialogpop', { isVisible: !dialog.hidden, triggerNode: trigger, dialogNode: dialog});

    return;
  };

  // Initialize a dialog modal
  const init = (dialogTrigger, dialogContent, scrollbar) => {
    const uniqueID = Date.now().toString().slice(-8);
    const dialog = setDialogElement(dialogTrigger, dialogContent, uniqueID);
    const focusTrap = require('../utils/a11y/focus-trap.js');

    setDialogTrigger(dialogTrigger, uniqueID);
    setDialogContent(dialogContent, dialog, dialogTrigger);

    // Inject the dialog at the end of the body
    document.body.appendChild(dialog);

    // Set the focus trap in the `dialog`
    focusTrap.init(dialog);

    // When clicked
    dialogTrigger.addEventListener('click', evt => {
      evt.preventDefault();

      return toggleDialog(dialog, evt.currentTarget, scrollbar);
    });

    // When escaped
    document.addEventListener('keydown', evt => {
      if ((evt.keyCode || evt.which) === 27
          && !dialog.hidden) {
        evt.preventDefault();
        return toggleDialog(dialog, dialogTrigger, scrollbar);
      }

      return null;
    });

    // When clicked outside
    document.body.addEventListener('click', evt => {
      if (!getClosest(evt.target, `#${dialogTrigger.id}`)
          && !getClosest(evt.target, `#${dialog.id}`)
          && !dialog.hidden) {
        evt.preventDefault();

        return toggleDialog(dialog, dialogTrigger, scrollbar);
      }

      return null;
    });
  };

  return {
    init : init,
    close : setDialogAsHidden
  };
})();

module.exports = Dialog;
