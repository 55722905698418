// @module notifier
const Notifier = (function() {
  const notifier = document.querySelector('[data-js-notifier=""]');

  const showNotification = (callback) => {
    notifier.hidden = false;

    if (window.localStorage.getItem('notifierConsent') === null) {
      window.localStorage.setItem('notifierConsent', 'false');
    }

    return callback && callback();
  };

  const hideNotification = (callback) => {
    notifier.hidden = true;
    notifier.className = 'notifier';

    return callback && callback();
  };

  const setCloseButton = (focusBack) => {
    const close = document.createElement('button');

    close.className = 'notifier__close';
    close.innerHTML = '<span class="visuallyhidden">Fermer la notification</span><svg aria-hidden="true" role="img" height="14px" width="14px"><use xlink:href="#ico_close_currentcolor"></use></svg>';
    close.type = 'button';

    close.addEventListener('click', evt => {
      hideNotification();

      return focusBack.focus();
    });

    return close;
  };

  const setConsentButton = (focusBack) => {
    const consent = document.createElement('button');

    consent.className = 'button-primary';
    consent.innerHTML = "Accepter";
    consent.type = 'button';

    consent.addEventListener('click', evt => {
      hideNotification();

      window.localStorage.setItem('notifierConsent', 'true');

      return focusBack.focus();
    });

    return consent;
  };

  const init = (content, options) => {
    if (!notifier || !content) return;
    const defaultOptions = {
      type: 'neutral',
      duration: 3600000,
      closable: null,
    };
    const _options = Object.assign({}, defaultOptions, options);
    const container = notifier.querySelector('[data-js-notifier="content"]');
    const type = (['negative','neurtral','positive','consent'].indexOf(_options.type) > -1 ? _options.type : 'neutral');
    const activeElement = document.activeElement;
    
    const is_consented = window.localStorage.getItem('notifierConsent') === 'true'
        || navigator.cookieEnabled == false
        || document.location.search.includes("consentement"); // Désactiver la notice de consentement si la variable d'url "consentement" existe

    // Show the notification
    if (!is_consented
        || type !== 'consent') {
      showNotification(() => {
        container.innerHTML = content;
        notifier.classList.add(`notifier--${type}`);

        // Optional close button
        if (_options.closable
            && _options.type !== 'consent'
              && notifier.querySelector('.notifier__close') === null ) {
          container.insertAdjacentElement(
            'afterend',
            setCloseButton(activeElement)
          );
        }

        // If consent add a consent button
        if (_options.type === 'consent') {
          container.insertAdjacentElement(
            'beforeend',
            setConsentButton(activeElement)
          );
        }
      });

      // Hide the notification after a while
      setTimeout(() => {
        hideNotification(() => {
          if ( container.nextElementSibling ) {
            container.parentElement.removeChild(container.nextElementSibling);
          }
          container.innerHTML = '';
        });
      }, _options.duration);
    }
    return;
  };

  return {
    init : init
  };
})();

module.exports = Notifier;
