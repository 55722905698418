// Module de consentement des cookies pour la loi 25
//
// Rémi Payette
const consentementCookies = (function () {
  const init = () => {
    require("vanilla-cookieconsent/dist/cookieconsent.js");
    var cc = initCookieConsent();

    // run plugin with your configuration
    cc.run({
      current_lang: "fr",
      autoclear_cookies: true, // default: false
      page_scripts: true, // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      delay: 6000,                               // default: 0
      // auto_language: '',                      // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      // hide_from_bots: true,                   // default: true
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      revision: 1,                               // default: 0
      gui_options: {
        consent_modal: {
          layout: "cloud", // box/cloud/bar
          position: "bottom center", // bottom/middle/top + left/right/center
          transition: "slide", // zoom/slide
          swap_buttons: false, // enable to invert buttons
        },
        settings_modal: {
          layout: "box", // box/bar
          position: "left", // left/right
          transition: "slide", // zoom/slide
        },
      },
      onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once on the first accept/reject action
      },

      onAccept: function (cookie) {
        // callback triggered on the first accept/reject action, and after each page load
      },

      onChange: function (cookie, changed_categories) {
        // callback triggered when user changes preferences after consent has already been given
      },

      languages: {
        fr: {
          consent_modal: {
            title: "Gestion des témoins de navigation",
            description:
              'Ce site Web utilise des témoins pour optimiser votre expérience de navigation. Certains sont essentiels au bon fonctionnement du site, alors que d’autres servent à collecter des statistiques. Vous êtes libre de <a href="#s-cnt" data-cc="c-settings" class="cc-link">choisir les témoins non essentiels</a> que vous acceptez d’activer. Pour plus d’information, consultez notre <a href="https://www.oqlf.gouv.qc.ca/politique.html" class="cc-link">Politique de confidentialité</a>.<link href="/typo3conf/ext/tm_core/Resources/Public/node_modules/vanilla-cookieconsent/dist/cookieconsent.css" rel="stylesheet" type="text/css" />',
            primary_btn: {
              text: "Tout accepter",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Tout refuser",
              role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Gestion des témoins de navigation",
            save_settings_btn: "Enregistrer ma sélection",
            accept_all_btn: "Tout accepter",
            reject_all_btn: "Tout refuser",
            close_btn_label: "Fermer",
            // cookie_table_caption: 'Cookie list',
            cookie_table_headers: [
              { col1: "Name" },
              { col2: "Domain" },
              { col3: "Expiration" },
              { col4: "Description" },
            ],
            blocks: [
              {
                title: "Témoins essentiels (obligatoire)",
                description:
                  "Les témoins essentiels servent à organiser et à afficher les pages du site Web de l’Office de façon optimale en tenant compte de vos préférences. Ces témoins contribuent à rendre le site Web de l’Office utilisable et sécuritaire en activant des fonctions de base comme celles associées à des formulaires. Un site Web ne peut pas fonctionner correctement sans ces témoins. Ils sont donc obligatoires.",
                toggle: {
                  value: "obligatoires",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Témoins de suivi",
                description:
                  "Les témoins de suivi, tels que ceux de Google Analytics et de Google Tag Manager, servent à repérer des problèmes ainsi qu’à recueillir des informations et des statistiques sur les habitudes d’utilisation des contenus Web. L’Office procède à l’analyse de ces données dans le but d’améliorer le fonctionnement de son site Web. Ces données anonymes peuvent être conservées à l’extérieur du Québec et aussi être partagées sur des comptes comme Google Ads et Google Search Console.",
                toggle: {
                  value: "analytics", // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  // list of all expected cookies
                  {
                    col1: "^_ga", // match all cookies starting with "_ga"
                    col2: "google.com",
                    col3: "2 years",
                    col4: "description ...",
                    domain: 'gouv.qc.ca',
                    is_regex: true,
                  },
                  {
                    col1: "_gid",
                    col2: "google.com",
                    col3: "1 day",
                    col4: "description ...",
                    domain: 'gouv.qc.ca',
                  },
                ],
              },
              {
                title: "Pour en savoir plus",
                description:
                  'Consultez notre <a href="https://www.oqlf.gouv.qc.ca/politique.html" target="_blank" rel="external" class="cc-link">Politique de confidentialité</a>.',
              },
            ],
          },
        },
      },
    });
  };

  return {
    init: init,
  };
})();

module.exports = consentementCookies;
