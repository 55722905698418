// @module accordion
// Add an [aria-hidden="false"] attribute to the [data-js-accordion="panel"] elements to have the accordion opened by default.
const Accordion = (function() {
    const matches = require('../utils/matches.js');
    const accordions = Array.prototype.slice.call(
        document.querySelectorAll('[data-js-accordion="item"]')
    );

    // Switch between accordion visible/hidden states
    const toggleAccordion = (trigger, accordion) => {
        const panel = document.getElementById(trigger.getAttribute('aria-controls'));

        if (trigger.getAttribute('aria-expanded') == 'true') {
            trigger.setAttribute('aria-expanded', 'false');

            panel.hidden = true;
            panel.setAttribute('aria-hidden', 'true');
            panel.setAttribute('tabindex', '-1');
            panel.style.height = null;

            accordion.classList.remove('accordion--expanded');
        } else {
            trigger.setAttribute('aria-expanded', 'true');

            panel.hidden = false;
            panel.setAttribute('aria-hidden', 'false');
            panel.setAttribute('tabindex', '0');
            panel.style.height = accordion.dataset.panelHeight;

            accordion.classList.add('accordion--expanded');
        }

        return;
    };

    // Set the trigger button
    const setAccordionTrigger = (accordion) => {
        const header = accordion.querySelector('[data-js-accordion="header"]');
        const trigger = document.createElement('button');
        const panel = accordion.querySelector('[data-js-accordion="panel"]');

        trigger.classList.add('accordion__trigger');
        trigger.dataset.jsAccordion = 'trigger';
        trigger.id = `${accordion.id}_trigger`;
        trigger.innerHTML = header.innerHTML;
        trigger.setAttribute('aria-controls', `${accordion.id}_panel`);
        trigger.setAttribute('type', 'button');

        // if the panel is set to be open by default
        if (panel.getAttribute('aria-hidden') === 'false') {
            trigger.setAttribute('aria-expanded', 'true');
        } else {
            trigger.setAttribute('aria-expanded', 'false');
        }

        trigger.addEventListener('click', evt => toggleAccordion(evt.target, accordion));

        header.innerHTML = '';

        return header.appendChild(trigger);
    };

    // Set the panel element
    const setAccordionPanel = (accordion, defaultHeight) => {
        const panel = accordion.querySelector('[data-js-accordion="panel"]');

        panel.setAttribute('aria-labelledby', `${accordion.id}_trigger`);
        panel.setAttribute('role', 'region');

        // To be open by default set [aria-hidden] to false
        // Otherwise
        if (panel.getAttribute('aria-hidden') !== 'false') {
            panel.setAttribute('aria-hidden', 'true');
            panel.setAttribute('hidden', '');
            panel.setAttribute('tabindex', '-1');
        } else {
            panel.style.height = defaultHeight;
        }

        return panel;
    };

    const init = () => {
        return accordions.forEach(accordion => {
            // const initialHeight = accordion.querySelector('[data-js-accordion="innerpanel"]').getBoundingClientRect().height;

            // accordion.dataset.panelHeight = `${initialHeight}px`;

            setAccordionTrigger(accordion);
            setAccordionPanel(accordion, accordion.dataset.panelHeight);
        });
    };


    return {
        init : init
    };
})();

module.exports = Accordion;
