// @module toggletip

const ToggleTip = (function() {
  const toggletips = [].slice.call(document.querySelectorAll('[data-js-toggletip]'));
  const matches = require('../utils/matches.js');
  const getClosest = require('../utils/get-closest.js');

  const setTipBubble = toggletip => {
    const tip = document.createElement('span');

    tip.className = 'toggletip__tip';
    tip.dataset.jsToggletip = 'tip';
    tip.hidden = true;
    tip.innerHTML = `<span class="toggletip__wrap" tabindex="0"><span>${toggletip.innerHTML}</span></span>`;

    return tip;
  };

  const setToggleButton = toggletip => {
	  const toggletipType = toggletip.dataset.jsToggletip;
    let toggle = document.createElement('span');
    let toggletipLink;
    let toggletipString;

    // Éliminer les toggletip-decoration qui ne sont là que pour la version sans javascript
    toggletip.parentNode.querySelectorAll('.toggletip-decoration').forEach( element => {
      element.parentNode.removeChild( element );
    });

    // The toggletip is definition or audio
    // Then get the previousElementSibling innerText
    // Otherwise return empty string
    if (toggletipType === 'definition'
        || toggletipType === 'audio'
        && matches(toggletip.previousElementSibling, '[data-js-dfntip="link"],[data-js-audiotip="link"]')) {
      toggletipLink = toggletip.previousElementSibling;
      toggletipString = `<span class="toggletip__text">${toggletipLink.innerHTML}</span>`;
    } else {
      toggletipString = '';
    }

    toggle.className = 'toggletip__toggle';
    toggle.dataset.jsToggletip = 'toggle';
    toggle.innerHTML = `
      ${toggletipString}
      <span class="visuallyhidden">Afficher l'infobulle</span>
      <svg aria-hidden="true" role="img" height="16px" width="16px"><use xlink:href="#ico_toggletip_${toggletipType}"></use></svg>
    `;

    // Remove the a[data-js-audiotip="link"] or a[data-js-dfntip="link"]
    if (toggletipLink) {
      toggletipLink.parentNode.removeChild(toggletipLink);
    }

    return toggle;
  };

  const setTipPosition = (toggle, tip) => {
    let toggleRect = toggle.getBoundingClientRect();

    if ((document.body.offsetWidth - toggleRect.right) > tip.offsetWidth) {
      tip.className = 'toggletip__tip toggletip__tip--right';
    } else if (toggleRect.left > tip.offsetWidth) {
      tip.className = 'toggletip__tip toggletip__tip--left';
    } else {
      tip.className = 'toggletip__tip';
    }

    return;
  };

  const setCloseButton = toggletip => {
    let close = document.createElement('button');

    close.className = 'toggletip__close';
    close.innerHTML = '<span class="visuallyhidden">Fermer l\'infobulle</span><svg aria-hidden="true" role="img" height="14px" width="14px"><use xlink:href="#ico_close"></use></svg>';
    close.type = 'button';

    close.addEventListener('click', () => {
      const toggle = toggletip.querySelector('button[aria-expanded="true"]');

      toggle.click();
      toggle.focus();
    });

    return close;
  };
  


  const init = () => {
    return toggletips.forEach(toggletip => {
      const tip = setTipBubble(toggletip);
      const toggle = setToggleButton(toggletip);
      const collapsible = require('../utils/collapsible.js');

      let visuel = false;

	    toggletip.classList.add('toggletip');
      toggletip.innerHTML = '';
      toggletip.appendChild(tip);

      tip.insertAdjacentElement('beforebegin', toggle);

      if ( tip.querySelector(".toggletip__wrap").innerText != "" ) {
        visuel = true;
      }

      collapsible(
        toggletip.querySelectorAll('[data-js-toggletip="toggle"]'),
        toggletip.querySelectorAll('[data-js-toggletip="tip"]'),
        true,
        visuel
      );

      if (matches(toggletip, '[data-js-toggletip="audio"]')) {
        let audioTip = toggletip.querySelector('[data-js-audiotip="audio"]');
        let playing = false;

        toggletip.querySelector('[data-js-collapsible="button"]')
          .addEventListener('click', evt => {
            evt.stopPropagation();

            if (!playing || (!visuel && audioTip.ended) ) {
              audioTip.play();
              playing = true;
            } else if (!audioTip.ended) {
              audioTip.pause();
              audioTip.currentTime = 0;
              playing = false;
            } else {
              audioTip.currentTime = 0;
              playing = false;
            }
          });
      }


      tip.addEventListener('collapse', evt => {

        setTipPosition(evt.currentTarget.previousElementSibling, evt.currentTarget);

        if (evt.detail.isExpanded
            && getClosest(evt.detail.targetNode, '.content-element,.terms')) {
          getClosest(evt.detail.targetNode, '.content-element,.terms').classList.add('content-element--above-next');
        } else if (!evt.detail.isExpanded
                   && getClosest(evt.detail.targetNode, '.content-element,.terms')){
          getClosest(evt.detail.targetNode, '.content-element,.terms').classList.remove('content-element--above-next');
        }
      });

      tip.insertAdjacentElement('beforeend', setCloseButton(toggletip));
    });
  };

  return {
    init : init
  };
})();

module.exports = ToggleTip;
