// @param {Object} `fnCallback` The function to throttle
// @param {Number} `delay` The setTimeout delay in milisecond
// @return {Object} `fnCallback`
// @usage window.addEventListener('scroll', throttle(function(e) {...}, 50));
// @source <http://www.grafikart.fr/tutoriels/javascript/debounce-throttle-642>
// @about Reduce the amount of times a function can run
module.exports = (fnCallback, delay) => {
    var last;
    var timer;

    return function() {
        var context = this;
        var now = +new Date();
        var args = arguments;

        if (last && now < last + delay) {
            clearTimeout(timer);

            timer = setTimeout(function() {
                last = now;
                fnCallback.apply(context, args);
            }, delay);

        } else {
            last = now;

            fnCallback.apply(context, args);
        }
    };
};
