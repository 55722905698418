// Accordion module
const Introjs = (function() {
  const init = () => {
    let doneTourAccueil = localStorage.getItem('doneTourAccueil') !== '1';
    let doneTourRecherche = localStorage.getItem('doneTourRecherche') !== '1';
    console.log(doneTourAccueil);
    //si déjà complété ou pas sur l'accueil

    //doneTourAccueil = false;
    //doneTourRecherche = false;
    var element = document.createElement("link");
    // Si page d'accueil et pas fini la demo
    if ((!doneTourAccueil) && document.querySelector('body.accueil') && (document.querySelectorAll('body.accueil').length === 1)) {

      //ajout du style dans le head
      element.setAttribute("rel", "stylesheet");
      element.setAttribute("type", "text/css");
      element.setAttribute("href", "typo3conf/ext/tm_core/Resources/Public/node_modules/intro.js/introjs.css");
      document.getElementsByTagName("head")[0].appendChild(element);
      //console.log(element);

      let introJs = require('intro.js');
      introJs = introJs();

      introJs.setOptions({

        steps: [
          {
            intro: "<p>Dans le champ de recherche, inscrivez un ou quelques mots-clés. Puis, cliquez sur la loupe (ou tapez sur la touche Entrée de votre clavier) pour lancer la recherche.</p>",
            element: '.search-banner__wrap',
            position: 'top'
          },
          {
            element: ".search-banner__wrap",
            intro: "<p>Dès que vous aurez tapé au moins trois caractères, des suggestions de saisie semi-automatique vous seront présentées. Sélectionnez la suggestion qui vous convient, le cas échéant.</p>",
            position: 'top'
          },

          {
            element: ".search-banner__wrap",
            intro: "<p>Pour chercher une expression exacte, utilisez des guillemets pour encadrer votre requête. Pour connaître les autres opérateurs de recherche, consultez la <a href='a-propos-de-la-vitrine-linguistique/foire-aux-questions'>foire aux questions</a>.</p>",
            position: 'top'
          },
          {
            element: '.search-banner__options',
            intro: "<p>Si vous souhaitez limiter votre recherche au GDT ou à la BDL, décochez la case qui correspond à l’outil que vous voulez exclure.</p>",
            position: 'top'
          },
          {
            element: document.querySelector("#searchBannerBDL").closest('div.search-banner__opt'),
            intro: "<p>Interrogez la BDL pour vos questions concernant la grammaire, l’orthographe, la syntaxe, le vocabulaire, les emprunts à l’anglais, la ponctuation, la prononciation, la typographie, les noms propres, les abréviations et les symboles ou la rédaction et la communication.</p>",
            position: 'top'
          },
          {
            element: document.querySelector("#searchBannerGDT").closest('div.search-banner__opt'),
            intro: "<p>Interrogez le GDT si vous cherchez des renseignements sur le vocabulaire spécialisé (terminologie), sur l’équivalent en français d’un terme anglais ou d’un terme d’une autre langue, sur la définition exacte d’un terme ou sur son acceptabilité.</p>",
            position: 'top'
          },
          {
            element: '.search-banner__history',
            intro: "<p>Retrouvez facilement vos requêtes précédentes en utilisant le bouton Historique.</p>",
            position: 'top'
          },
          {
            element: '#n>.nav__wrapper>.nav__list',
            intro: "<p>Accédez à diverses ressources offertes par l’Office québécois de la langue française à partir de la section des liens rapides.</p>",
            position: 'top'
          }
        ]

      });
      //tradution
      introJs.setOption("nextLabel","Suivant");
      introJs.setOption("prevLabel","Précédent");
      introJs.setOption("skipLabel","X");
      introJs.setOption("doneLabel","Terminer");
      introJs.setOption("scrollTo","tooltip");

      introJs.onbeforechange(function(){
        if (this._currentStep === 0 || this._currentStep === 1 || this._currentStep === 2) {
          setTimeout(function(){
            introJs.refresh();
          },100);
        }

        window.dataLayer.push({event: 'tutoriel', 'tutoriel': 'recherche', 'étape': (this._currentStep+1).toString() });
      });

      // add a flag when we exit
      introJs.oncomplete(function() {
        localStorage.setItem('doneTourAccueil', '0');
      });

      // add a flag when we exit
      introJs.onexit(function() {
        localStorage.setItem('doneTourAccueil', '0');
      });

      introJs.start();
      window.scroll(0,0);
    }

    // Si page recherche et pas fini la demo et pas sans aucun résultat
    else if(
      (!doneTourRecherche) &&
        document.querySelector('body.recherche') !== null &&
        (document.querySelectorAll('body.recherche').length === 1) &&
        document.querySelector('.search-results__list') !== null
    ) {
      //console.log(document.querySelector('.search-results__list'));
      //ajout du style dans le head
      element.setAttribute("rel", "stylesheet");
      element.setAttribute("type", "text/css");
      element.setAttribute("href", "typo3conf/ext/tm_core/Resources/Public/node_modules/intro.js/introjs.css");
      document.getElementsByTagName("head")[0].appendChild(element);

      let introJs = require('intro.js');
      introJs = introJs();

      introJs.setOptions({

        steps: [
          {
            intro: "<p>Le nombre de résultats correspondant à votre requête est indiqué ici.</p>",
            element: ".search-results__count",
            position: 'top'
          },
          {
            element: "#suggesttutoriel",
            intro: "<p>Des requêtes semblables à la vôtre vous sont présentées. Vous pouvez relancer votre recherche en cliquant sur l’une des propositions.</p>",
            position: 'top'
          },

          {
            element: ".facets-sidebar",
            intro: "<p>Utilisez les filtres pour raffiner les résultats de votre recherche.</p>",
            position: 'top'
          },
          {
            element: '.switch-button.switch-button--small',
            intro: "<p>Glissez un commutateur à gauche pour retirer les résultats provenant de l’un des deux outils.</p>",
            position: 'top'
          },
          {
            element: '#facet_themes_stringM',
            intro: "<p>Cochez une case Thèmes pour ne voir apparaître que les résultats qui correspondent à ce thème. Vous pouvez aussi en cocher plusieurs.</p>",
            position: 'top'
          },
          {
            element: '.facets__thematicIndex',
            intro: "<p>Cliquez sur ce lien pour naviguer dans la BDL au moyen de l’index thématique.</p>",
            position: 'top'
          },
          {
            element: '#facet_langue_stringM',
            intro: "<p>Sélectionnez la langue dans laquelle vous avez inscrit votre requête pour exclure les résultats trouvés dans d'autres langues.</p>",
            position: 'top'
          },
          {
            element: '#facet_domaines_stringM',
            intro: "<p>Cochez la case du ou des domaines qui vous intéressent.</p>",
            position: 'top'
          },
          {
            element: '#facet_auteur_stringM',
            intro: "<p>Cochez la case Office québécois de la langue française afin de ne voir que les fiches produites par l’Office.</p>",
            position: 'top'
          },
          {
            intro: "<p>Attention! Si vous sélectionnez un filtre dans le panneau de la BDL, les résultats du GDT seront masqués, et vice-versa.</p>",
            position: 'top'
          },
          {
            element: '.facets-sidebar__reset',
            intro: "<p>Cliquez sur Réinitialiser pour désactiver les filtres que vous avez sélectionnés.</p>",
            position: 'top'
          }
        ].filter(function(obj) { let filter = document.querySelector(obj.element); if(!obj.element){ filter = 'body';} return filter; })

      });
      //tradution
      introJs.setOption("nextLabel","Suivant");
      introJs.setOption("prevLabel","Précédent");
      introJs.setOption("skipLabel","X");
      introJs.setOption("doneLabel","Terminer");


      introJs.setOption("scrollTo","tooltip");

      introJs.onbeforechange(function(target){
        if (this._currentStep === 0) {
          setTimeout(function(){
            introJs.refresh();
          },100);
        }
        if(target.className==='introjsFloatingElement'){
          setTimeout(function(){
            let tooltipdiv = document.querySelector('.introjs-tooltipReferenceLayer');
            tooltipdiv.style.top = "10%";
            window.scroll(0,0);
          },100);
        }
        if (target.className==='facets-sidebar' ||
            target.className==='switch-button switch-button--small' ||
            target.id==='facet_themes_stringM' ||
            target.className==='facets__thematicIndex' ||
            target.id==='facet_langue_stringM' ||
            target.id==='facet_domaines_stringM' ||
            target.id==='facet_auteur_stringM')
        {
          setTimeout(function(){
            document.querySelector('[data-js-facets="sidebar"] .facets-sidebar__header [data-js-collapsible="button"]').click();
            introJs.refresh();
          },100);
        }
          window.dataLayer.push({event: 'tutoriel', 'tutoriel': 'résultats de recherche', 'étape': (this._currentStep+1).toString() });
      });

      // add a flag when we exit
      introJs.oncomplete(function() {
        localStorage.setItem('doneTourRecherche', '0');
      });

      // add a flag when we exit
      introJs.onexit(function() {
        localStorage.setItem('doneTourRecherche', '0');
      });

      introJs.start();
      window.scroll(0,0);
    }

    // Bouton pour resetter le tutoriel
    const links = [].slice.call(document.querySelectorAll('[data-function="resetIntrojs"]'));

    links.forEach(function(link) {
      link.addEventListener('click', function(){
        localStorage.setItem('doneTourRecherche', '1');
        localStorage.setItem('doneTourAccueil', '1');
        console.log(doneTourAccueil);
      });
    });

  };


  return {
    init : init
  };
})();

module.exports = Introjs;
