'use strict';
/**
 * Find array elements. Executes a callback for each element, returns
 * the first element for which its callback returns a truthy value.
 * @return {Element} The value of the first element in the array that satisfies the provided testing function
 * @source <https://github.com/stefanduberg/array-find>
 */
module.exports = (array, predicate, context) => {
    if (typeof Array.prototype.find === 'function') {
        return array.find(predicate, context);
    }

    context = context || this;
    var length = array.length;
    var i;

    if (typeof predicate !== 'function') {
        throw new TypeError(predicate + ' is not a function');
    }

    for (i = 0; i < length; i++) {
        if (predicate.call(context, array[i], i, array)) {
            return array[i];
        }
    }

    return null;
};
