// @module sondage
const SondageDialog = (function() {
  const sondage = document.querySelector('#sondage');
  const sondageTrigger = document.querySelector('#sondageTrigger');

  const init = () => {
    const dialog = require('./dialog.js');

    if ( sondage && sondageTrigger) {
      if (!localStorage.getItem('sondage') || localStorage.getItem('sondage') != sondage.dataset.nom ) {
        let random = Math.ceil(Math.random() * sondage.dataset.rate);

        console.log(random);

        if ((random ==  1) && (document.getElementById('sondageAjax'))) {
          let httpRequest = new XMLHttpRequest();

          httpRequest.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
              sondage.innerHTML = this.responseText;

              dialog.init(
                sondageTrigger,
                sondage
              );

              setTimeout(function() {
                sondageTrigger.click();
              }, 500);

              [].slice.call(sondage.querySelectorAll('a.button')).forEach(btn => {
                btn.addEventListener('click', evt => {
                  if (btn.getAttribute('role') === 'button') {
                      evt.preventDefault();
                  }

                  return dialog.close(sondage.parentNode, sondageTrigger);
                });
              });

              localStorage.setItem('sondage', sondage.dataset.nom);
            }

            return;
          };

          if (document.getElementById('sondageAjax').dataset.url) {
            httpRequest.open('GET', document.getElementById('sondageAjax').dataset.url+"&ts=" + new Date().getTime(), true);
            httpRequest.send();
          }
        }
      }
    }

    return;
  };

  return {
    init : init
  };
})();

module.exports = SondageDialog;
