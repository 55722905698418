// @module switch button
// @usage
// <div class="switch-button" data-js-switch="false">
//   <span class="switch-button__label" id="commutateurLabel_1">Aimez-vous les commutateurs?</span>
//   <button
// 	   aria-checked="false"
// 	   aria-labelledby="commutateurLabel_1"
// 	   data-js-switch=""
// 	   id="commutateur_1"
// 	   type="button"
// 	   role="switch"
// 	   value="false">
// 	  <span class="visuallyhidden">Oui</span>
// 	  <span class="visuallyhidden">Non</span>
//  </button>
// </div>
const ButtonSwitch = (function() {
  const switchButtons = [].slice.call(document.querySelectorAll('[data-js-switch]'));
  const triggerCustomEvent = require('../utils/trigger-custom-event.js');

  const init = () => {
    return switchButtons.forEach(switchButton => {
      switchButton.parentNode.setAttribute('data-js-switch', switchButton.getAttribute('aria-checked'));

      switchButton.addEventListener('click', evt => {
        let checked = evt.target.getAttribute('aria-checked') === 'true';

        evt.target.setAttribute('aria-checked', !checked);
        evt.target.value = !checked;
        evt.target.parentNode.setAttribute('data-js-switch', !checked);

        triggerCustomEvent(switchButton, 'onswitch', { isChecked: !checked});
      });
    });
  };

  return {
    init : init
  };
})();

module.exports = ButtonSwitch;
