// @module etiquettes

const Etiquette = (function() {
  const etiquettes = [].slice.call(document.querySelectorAll('[data-js-pill]'));
  const getClosest = require('../utils/get-closest.js');
  const setDefinition = etiquette => {
    let definition = document.createElement('span');

    definition.className = 'excludedFromSearch';
	definition.dataset.jsToggletip = 'definition';
    definition.innerHTML = etiquette;

    return definition;
  };
  const setTipBubble = toggletip => {
    const tip = document.createElement('span');

    tip.className = 'toggletip__tip';
    tip.dataset.jsToggletip = 'tip';
    tip.hidden = true;
    tip.innerHTML = `<span class="toggletip__wrap" tabindex="0"><span>${toggletip.innerHTML}</span></span>`;
    return tip;
  };
  
  const setToggleButton = toggletip => {
    let toggle = document.createElement('span');
    let toggletipLink;
    let toggletipString;

    toggletipLink = toggletip.previousElementSibling;
    toggletipString = `<span class="toggletip__text">${toggletipLink.innerHTML}</span>`;

    toggle.className = 'toggletip__toggle';
    toggle.dataset.jsToggletip = 'toggle';
    toggle.innerHTML = `
      ${toggletipString}
      <span class="visuallyhidden">Afficher l'infobulle</span>
      <svg aria-hidden="true" role="img" height="16px" width="16px"><use xlink:href="#ico_toggletip_definition"></use></svg>
    `;

    // Remove the a[data-js-audiotip="link"] or a[data-js-dfntip="link"]
    if (toggletipLink) {
      toggletipLink.parentNode.removeChild(toggletipLink);
    }

    return toggle;
  };

  const setTipPosition = (toggle, tip) => {
    let toggleRect = toggle.getBoundingClientRect();

    if ((document.body.offsetWidth - toggleRect.right) > tip.offsetWidth) {
      tip.className = 'toggletip__tip toggletip__tip--right';
    } else if (toggleRect.left > tip.offsetWidth) {
      tip.className = 'toggletip__tip toggletip__tip--left';
    } else {
      tip.className = 'toggletip__tip';
    }
    return;
  };

  const setCloseButton = toggletip => {
    let close = document.createElement('button');

    close.className = 'toggletip__close';
    close.innerHTML = '<span class="visuallyhidden">Fermer l\'infobulle</span><svg aria-hidden="true" role="img" height="14px" width="14px"><use xlink:href="#ico_close"></use></svg>';
    close.type = 'button';

    close.addEventListener('click', () => {
      const toggle = toggletip.querySelector('button[aria-expanded="true"]');

      toggle.click();
      toggle.focus();
    });

    return close;
  };
  const init = () => {
	
    return etiquettes.forEach(pill => {
		if(etiquettesJson[pill.dataset.jsId.toLowerCase()]){
			const definition = setDefinition(etiquettesJson[pill.dataset.jsId.toLowerCase()]);
		
			pill.insertAdjacentElement('afterend',definition);
			
			const tip = setTipBubble(definition);
			const toggle = setToggleButton(definition);
			const collapsible = require('../utils/collapsible.js');
	  
			definition.classList.add('toggletip');
			definition.innerHTML = '';
			definition.appendChild(tip);
			
			tip.insertAdjacentElement('beforebegin', toggle);
	  
			collapsible(
			  definition.querySelectorAll('[data-js-toggletip="toggle"]'),
			  definition.querySelectorAll('[data-js-toggletip="tip"]'),
			  true
			);

			tip.addEventListener('collapse', evt => {

				setTipPosition(evt.currentTarget.previousElementSibling, evt.currentTarget);

				if (evt.detail.isExpanded && getClosest(evt.detail.targetNode, '.content-element,.terms')) {
				  getClosest(evt.detail.targetNode, '.content-element,.terms').classList.add('content-element--above-next');
				} else if (!evt.detail.isExpanded && getClosest(evt.detail.targetNode, '.content-element,.terms')){
				  getClosest(evt.detail.targetNode, '.content-element,.terms').classList.remove('content-element--above-next');
				}
			});

			tip.insertAdjacentElement('beforeend', setCloseButton(definition));
		}
		else{
			let newPill = document.createElement('span');
			newPill.innerHTML = pill.innerHTML;
			pill.insertAdjacentElement('afterend',newPill);
			pill.remove();
		}
	});
  };

  return {
    init : init
  };
})();

module.exports = Etiquette;