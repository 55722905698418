// @module breadcrumb
const Breadcrumb = (function() {
  const breadcrumb = document.querySelector('[data-js-breadcrumb="list"]');
  // Get crumbs except first and last two items
  const getEllipsisItems = crumbs => {
    return crumbs.slice(2,(crumbs.length - 2));
  };
  const collapsible =  require('../utils/collapsible.js');

  // Build a expendable submenu
  const setEllipsisMenu = ellipsisItems => {
    const ellipsis = document.createElement('li');

    ellipsis.className = 'breadcrumb__item breadcrumb__ellipsis';
    ellipsis.id = "breadcrumbEllipsis";
    ellipsis.insertAdjacentHTML(
      'beforeend',
      '<span data-js-breadcrumb="trigger"><span class="visuallyhidden">Afficher les niveaux intermédiaires</span> <span aria-hidden="true"></span></span><ul class="breadcrumb__sublist" data-js-breadcrumb="sublist" hidden=""></ul><svg aria-hidden="true" role="img" class="ico-chevronariane" height="7px" width="5px"><use xlink:href="#ico_chevronariane"></use></svg>'
    );

    ellipsisItems.forEach(item => {
      ellipsis.querySelector('ul').appendChild(item);
    });

    // Show/hide the ellipsis menu
    collapsible(
      ellipsis.querySelectorAll('[data-js-breadcrumb="trigger"]'),
      ellipsis.querySelectorAll('[data-js-breadcrumb="sublist"]'),
      true
    );

    // Move nav[data-js-breadcrumb=""] in the z-index
    ellipsis.querySelector('[data-js-breadcrumb="sublist"]')
      .addEventListener('collapse', evt => {
        let nav = document.querySelector('[data-js-breadcrumb=""]');

        if (evt.detail.isExpanded === true) {
          nav.style.zIndex = '4';
        } else {
          nav.style.zIndex = 'auto';
        }

        return;
      });

    return ellipsis;
  };

  const init = () => {
    if (!breadcrumb) return;
    const crumbs = [].slice.call(breadcrumb.querySelectorAll('li'));

    if (crumbs.length >= 5) {
      breadcrumb.querySelector('li:nth-child(2)')
        .insertAdjacentElement('afterend', setEllipsisMenu(getEllipsisItems(crumbs)));
    }

    return;
  };

  return {
    init : init
  };
})();

module.exports = Breadcrumb;
