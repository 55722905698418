// @param {Object} `fnCallback` The function to debounce
// @param {Number} `delay` The setTimeout delay in milisecond
// @return {Object} `fnCallback`
// @usage window.addEventListener('scroll', debounce(function(e) {...}, 50));
module.exports = (fnCallback, delay) => {
  let timer = null;

  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fnCallback.apply(context, args);
    }, delay);
  };
};
