// @module contenttable
const Contenttable = (function() {
  const matches = require('../utils/matches.js');
  const contenttables = [].slice.call(document.querySelectorAll('table'));
  const getClosest = require('../utils/get-closest.js');
  const dialog = require('./dialog.js').init;
  const chaineNeutre = require('../utils/chaineNeutre.js');

  const setFullscreenDialogTable = (table, origin) => {
    let dialogButton = document.createElement('button');
    let dialogTable = table.parentNode.cloneNode(true);

    dialogButton.classList.add('contenttable-scalable-button');
    dialogButton.innerHTML = '<svg aria-hidden="true" height="20px" width="20px" role="img" class="ico-fullscreen"><use xlink:href="#ico_fullscreen"></use></svg>&nbsp;<span>Agrandir le tableau</span>';
    dialogButton.setAttribute('type','button');
    dialog(dialogButton, dialogTable);

    return origin.insertAdjacentElement('afterbegin', dialogButton);
  };

  const setTableWrapper = table => {
    let gradient = document.createElement('div');
    let wrapper = document.createElement('div');
    let ico = `<div class="contenttable-ico" hidden=""><span class="visuallyhidden">Faites glisser</span><svg aria-hidden="true" height="18px" width="16px" role="img" class="ico-swipe"><use xlink:href="#ico_swipe"></use></svg></div>`;

    // Ajout d'un id si n'existe pas
    if (table.id === "") {
      table.id = 'table_' + Date.now().toString().slice(-8);
    }

    // Ajout de la classe tableau au content element s'il s'agit d'un <table> dans un Typoscript2ce plutôt qu'un tm_core_tableau
    let ce = getClosest(table, '.tx-typoscript2ce');
    if ( ce !== null) {
      ce.classList.add('tableau');
    }

    // Gradient setup
    gradient.classList.add('contenttable-gradient');
    gradient.dataset.jsContenttable = 'gradient';
    gradient.insertAdjacentHTML('afterbegin', ico);

    // Wrapper setup
    wrapper.classList.add('contenttable-wrapper');
    wrapper.dataset.jsContenttable = 'wrapper';
    wrapper.setAttribute('role', 'group');

    // Table setup
    table.dataset.jsContenttable = 'table';
    table.dataset.jsLongueur = table.tBodies[0].rows.length;
    if ( table.dataset.jsLongueur == 1 ) {
      table.classList.add('une-ligne');
    }
    table.insertAdjacentElement('afterend', wrapper);

    // Move elements in the right places
    wrapper.appendChild(table);
    wrapper.insertAdjacentElement('afterend', gradient);

    // Provide fullscreen table
    setFullscreenDialogTable(table, gradient);

    return gradient.appendChild(wrapper);
  };

  const setAsScrollable = (table, parent) => {
    const gradient = getClosest(parent, '[data-js-contenttable="gradient"]');

    if (document.body.clientWidth < 640
        && table.querySelector('tbody > tr').childElementCount > 2) {
      parent.setAttribute('tabindex', '0');
      gradient.classList.add('is-scrollable');
      table.classList.add('contenttable--lg');

      if (table.caption) {
        table.caption.style.maxWidth = `${document.body.clientWidth - 56}px`;
      }

      return true;
    } else {
      parent.removeAttribute('tabindex');

      gradient.classList.remove('is-scrollable');
      table.classList.remove('contenttable--lg');

      if (table.caption) {
        table.caption.style = null;
      }

      return false;
    }
  };

  const setAsScalable = (table, parent) => {
    const gradient = getClosest(parent, '[data-js-contenttable="gradient"]');

    if (table.querySelector('tbody > tr').childElementCount > 2) {
      table.classList.add('is-large');
    } else {
      table.classList.remove('is-large');
    }

    if (table.clientWidth > parent.clientWidth
       && table.querySelector('tbody > tr').childElementCount > 2) {
      gradient.classList.add('is-scalable');
      table.classList.add('contenttable--lg');

      return true;
    } else {
      gradient.classList.remove('is-scalable');
      table.classList.remove('contenttable--lg');

      return false;
    }
  };

  const setRecherchable = (table, parent) => {
    const gradient = getClosest(parent, '[data-js-contenttable="gradient"]');
    const nbrLignesTotal = table.dataset.jsLongueur;
    
    // Seulement les long tableaux
    if ( nbrLignesTotal < 25 ) return false;

    // Si pas de caption, en ajouter un
    let titreTableau = table.querySelector("caption");
    if ( titreTableau === null ) {
      titreTableau = document.createElement('caption');
      table.prepend( titreTableau );
    }

    let champFiltre = document.createElement('input');
    let conteneurFiltre = document.createElement('div');
    let statutFiltre = document.createElement('p');

    conteneurFiltre.classList.add('form__field', 'form__field--input', 'contenttable-filtre');

    statutFiltre.id = 'statutFiltre-' + table.id;
    statutFiltre.classList.add('contenttable-statutFiltre');
    statutFiltre.setAttribute('role', 'status');
    statutFiltre.setAttribute('aria-live', 'polite');
    statutFiltre.innerHTML = nbrLignesTotal + " lignes dans ce tableau";
    statutFiltre.dataset.jsContenuSansFiltre = statutFiltre.innerHTML;

    champFiltre.setAttribute('placeholder', 'Rechercher dans le tableau');
    champFiltre.setAttribute('type', 'search');
    champFiltre.setAttribute('autocomplete', 'off');
    champFiltre.setAttribute('autocorrect', 'off');
    champFiltre.setAttribute('autocapitalize', 'off');
    champFiltre.setAttribute('spellcheck', 'false');
    champFiltre.setAttribute('aria-controls', table.id);
    champFiltre.setAttribute('aria-label', 'Filtrer les ' + nbrLignesTotal + ' lignes de ce tableau');

    table.setAttribute('aria-controls', statutFiltre.id);

    conteneurFiltre.appendChild( champFiltre );
    titreTableau.appendChild( conteneurFiltre );
    conteneurFiltre.after( statutFiltre );

    champFiltre.addEventListener('input', function(event) {
      let recherche = chaineNeutre(this.value);

      table.querySelectorAll("tbody > tr,tbody > template").forEach ( function(ligne) {
        let correspond = ligne.dataset.contenu.includes(recherche);

        // Cacher
        if ( ligne.tagName == "TR" && correspond == false ) {
          let nouv = document.createElement('template');
          nouv.innerHTML = ligne.innerHTML;
          nouv.dataset.contenu = ligne.dataset.contenu;
          ligne.replaceWith(nouv);
        }

        // Afficher
        if ( ligne.tagName == "TEMPLATE" && correspond == true ) {
          let nouv = document.createElement('tr');
          nouv.appendChild( ligne.content.cloneNode(true) );
          nouv.dataset.contenu = ligne.dataset.contenu;
          ligne.replaceWith(nouv);
        }
      });
      let lignesAffichees = table.tBodies[0].rows.length;
      if (lignesAffichees != nbrLignesTotal) {
        let pluriel = lignesAffichees>1?'s':'';
        statutFiltre.innerHTML = lignesAffichees + ' ligne' + pluriel + ' correspondante' + pluriel + ' sur ' + nbrLignesTotal;
      } else {
        statutFiltre.innerHTML = statutFiltre.dataset.jsContenuSansFiltre;
      }
    });

    // Initialiser le contenu recherchable des lignes
    table.querySelectorAll("tbody > tr").forEach(function(ligne, y){
      let tmp = ligne.cloneNode(true);

      // Éliminer les éléments cachés tel que les tooltips
      tmp.querySelectorAll(".excludedFromSearch").forEach(function(e){
        e.parentElement.removeChild(e);
      });

      ligne.dataset.contenu = chaineNeutre(tmp.textContent);
    });
    return true;
  }

  const init = () => {
    contenttables.forEach(table => {
      setTableWrapper(table);
      setAsScrollable(table, table.parentNode);
      setAsScalable(table, table.parentNode);
      setRecherchable(table, table.parentNode);

      window.addEventListener('resize', function (evt) {
        requestAnimationFrame(() => {
          setAsScrollable(table, table.parentNode);
          setAsScalable(table, table.parentNode);
        });
      });
    });
  };

  return {
    init : init
  };
})();

module.exports = Contenttable;
